import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Proband, ProbandRespone } from 'src/app/core/masters/_models/proband.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import {Observable, Subscription} from 'rxjs';
import { ProbandService } from 'src/app/core/masters/_services/proband.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from 'src/app/core/_base/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import {currentUser, User, Organisation, isCreateProbandAllowed, currentUserRoles} from 'src/app/core/auth';
import {Study} from "../../../../../core/masters/_models/study.model";
import {StudyService} from "../../../../../core/masters/_services/study.service";
@Component({
  selector: 'kt-proband-list',
  templateUrl: './proband-list.component.html',
  styleUrls: ['./proband-list.component.scss']
})
export class ProbandListComponent implements OnInit {

  // Table fields
  dataSource = new MatTableDataSource<Proband>();
  displayedColumns = ['patientId', 'firstName', 'gender', 'dateOfBirth', 'organisation', 'center', 'study',/* 'salutationType', */'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Proband>(true, []);
  probandResult: ProbandRespone;

  study$: Observable<Study[]>;
  public pageSize = 50;
  public currentPage = 0;
  public totalSize = 0;
  public start = 0;
  public end = this.totalSize;
  public searchTerm = "";
  public searchStudy="";
  pageEvent: PageEvent;

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];
  loggedUser: User;
  isCenteruserRole: boolean;
  isSuperAdminRole: boolean;
  isAddProbandRights: boolean;

  constructor(private _proBandService: ProbandService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    private router: Router,
    private _studyService: StudyService,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {

    this.study$ = this._studyService.getAllStudy();
    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Patients');
    this.store.pipe(select(currentUser)).subscribe(res => this.loggedUser = res);

    this.getAllProband(this.start, this.end, this.searchTerm);
    //this.store.pipe(select(isCreateProbandAllowed)).subscribe(res => this.isCreateProbandAllowed = res);
    const rolesSubscription = this.store.pipe(select(currentUserRoles))
      .subscribe(res => {
        if (res.length > 0) {
          let roles = new Array();
          res.forEach(element => {
            roles.push(Object.values(element)[0]);
          });

          (roles.includes('ROLE_SITE_ADMIN')  ||roles.includes('ROLE_JUNIOR_CRC') ) ? this.isAddProbandRights = true : this.isAddProbandRights = false;
          roles.includes('ROLE_SUPERADMIN') ? this.isSuperAdminRole = true : this.isSuperAdminRole = false;
        }

      });
  }

  getAllProband(start, end, searchTerm) {
    this.isLoading = true;
    this._proBandService.getAllProband(start, end, searchTerm, this.currentPage, this.pageSize)
      .subscribe(res => {
        this.probandResult = res;
        this.dataSource = new MatTableDataSource<Proband>(res.content);
        this.dataSource.sort = this.sort;
        this.isLoading = false;

        if (!this.totalSize) {
          this.dataSource.paginator = this.paginator;
          //this.totalSize = res.totalElements;
        }this.totalSize = res.totalElements;
      });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    this.start = this.currentPage * this.pageSize;
    this.end = (this.currentPage + 1) * this.pageSize;
    //const part = this.tableData.slice(start, end);
    //this.tableData = part;
    this.getAllProband(this.start, this.end, this.searchTerm);
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.probandResult.content.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.probandResult.content.length) {
      this.selection.clear();
    } else {
      this.probandResult.content.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.firstName,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(id: string) {
    this.router.navigate(['../Patient/edit', id], { relativeTo: this.activatedRoute });
  }

  deleteitem(_item: Proband) {
    const _title = 'Patient';
    const _description = 'Are you sure to permanently delete this Patient?';
    const _waitDesciption = 'Patient is deleting...';
    const _deleteMessage = `Patient has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._proBandService.deletProband(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllProband(this.start, this.end, this.searchTerm);
        });
      }
    });
  }

  viewitem(id: string) {
    this.router.navigate(['../Patient/view', id], { relativeTo: this.activatedRoute });
  }

  applyFilter(event) {
    //this.dataSource.filter = filterValue.trim().toLowerCase();
    this.start = 0;
    this.end = this.totalSize;
    this.searchTerm = 'ST:'+event.value;
    if (event.value) {
      this.getAllProband(this.start, this.end, this.searchTerm);
    }
  }

  applyPatientIdFilter(event) {
      console.log("=============")
    this.start = 0;
    this.end = this.totalSize;
    this.searchTerm = 'PI:'+event.value;
    console.log("event.value "+event.value)
    if (event.value) {
      console.log("========")
      this.getAllProband(this.start, this.end, this.searchTerm);
    }
  }

  isAddRights() {
/*    let roles = new Array();
    this.loggedUser.authorities.forEach(element => {
      roles.push(element?.authority);
    });

    roles.includes('ROLE_SENIOR_CRC') ? this.isAddProbandRights = true : this.isAddProbandRights = false;
    roles.includes('ROLE_SITE_ADMIN') ? this.isAddProbandRights = true : this.isAddProbandRights = false;
    return this.isAddProbandRights;*/
  }
  isEditRights(item: Proband) {
    return item['isEdit'] && this.isCenteruserRole ? true : false;
  }



}
