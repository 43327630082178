<kt-portlet>

    <kt-portlet-header [title]="getComponentTitle()" [class]="'card-head-lg'" [viewLoading$]="false">
        <ng-container ktPortletTools>
            <a [routerLink]="['/user-management/organisations']" class="btn btn-secondary mr-2" mat-raised-button
                matTooltip="Back to the users list">
                <i class="la la-arrow-left"></i>
                <span class="hidden-mobile">Back</span>
            </a>
            <a href="javascript:;" class="btn btn-secondary mr-2" mat-raised-button matTooltip="Reset changes"
                (click)="reset()">
                <i class="la la-cog"></i>
                <span class="hidden-mobile">Reset</span>
            </a>
            <a href="javascript:;" class="btn btn-primary mr-2" color="primary" (click)="onSumbit(true)"
                mat-raised-button matTooltip="Save & Continue">
                <span class="hidden-mobile">Save</span>
            </a>
            <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item color="primary" (click)="onSumbit(true)">Save & Exit</button>
                <button mat-menu-item color="primary">Save & Duplicate</button>
                <button mat-menu-item color="primary" (click)="onSumbit(false)">Save & Continue</button>
            </mat-menu>
        </ng-container>
    </kt-portlet-header>

    <div class="row justify-content-center py-12 px-8 py-lg-10 px-lg-10">
        <div class="col-xl-12 col-xxl-7">
            <!--begin: Form Wizard Form-->
            <form class="form form-group-seperator-dashed" [formGroup]="organisationForm">
                <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
                    (close)="onAlertClose($event)">
                    Oh snap! Change a few things up and try submitting again.
                </kt-alert>
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div class="row">

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter name of organisation"
                                    formControlName="nameOfOrganisation" />
                                <mat-error>Organisation name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter your
                                    <strong>organisation name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter your email" formControlName="email" />
                                <mat-error>Email is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter your
                                    <strong>email</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row form-group">
                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter your address" formControlName="address" />
                                <mat-error>Address is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter your
                                    <strong>address</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter your pincode" formControlName="pinCode" />
                                <mat-error>Pincode is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter your
                                    <strong>pincode</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row form-group">
                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter your primary contact"
                                    formControlName="primaryContact" />
                                <mat-error>Primary contact is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter your
                                    <strong>primary contact</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter your mobile number"
                                    formControlName="primaryContactMobileNUmber" />
                                <mat-error>Mobile number is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter your
                                    <strong>mobile number</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>


                </div>

                <div *ngIf='!isEdit' class="form form-group-seperator-dashed" formGroupName="user">
                    <h3>User Info</h3>
                    <div class="form-section form-section-first">
                        <div class="form-group row">
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Enter Username" formControlName="username" />
                                    <mat-error>Username is
                                        <strong>required</strong>
                                    </mat-error>
                                    <mat-hint align="start">Please enter
                                        <strong>Username</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Enter Full Name" formControlName="fullName" />
                                    <mat-error>Full Name is
                                        <strong>required</strong>
                                    </mat-error>
                                    <mat-hint align="start">Please enter
                                        <strong>Full Name</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input type="email" matInput placeholder="Enter Email" formControlName="email" />
                                    <mat-error>Email is
                                        <strong>required</strong>
                                    </mat-error>
                                    <mat-hint align="start">Please enter
                                        <strong>Email</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Enter First Name" formControlName="firstName" />
                                    <mat-error>First Name is
                                        <strong>required</strong>
                                    </mat-error>
                                    <mat-hint align="start">Please enter
                                        <strong>First Name</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Enter Last Name" formControlName="lastName" />
                                    <mat-error>Last Name is
                                        <strong>required</strong>
                                    </mat-error>
                                    <mat-hint align="start">Please enter
                                        <strong>Last Name</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Enter Phone Number" formControlName="phoneNumber" />
                                    <mat-error>Phone Number is
                                        <strong>required</strong>
                                    </mat-error>
                                    <mat-hint align="start">Please enter
                                        <strong>Phone Number</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <!-- </kt-portlet-body> -->
</kt-portlet>