import { BaseModel } from '../../_base/crud';

export class Permission extends BaseModel {
  id: number;
  title: string;
  level: number;
  parentId: number;
  isSelected: boolean;
  name: string;
  // tslint:disable-next-line
  _children: Permission[];
  actionName: string;
  controllerName: string;

  clear(): void {
    this.id = undefined;
    this.title = '';
    this.level = 1;
    this.parentId = undefined;
    this.isSelected = false;
    this.name = '';
    this._children = [];
    this.actionName = '';
    this.controllerName = '';
  }
}
