import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isScoreLabel'
})
export class IsScoreLabelPipePipe implements PipeTransform {

  transform(questionList: any, ...args: unknown[]): unknown {
    // return null;
    if (questionList) {
      console.warn('questionList11', questionList);

      let val = questionList?.filter(f => f.questionMapping.isScoreLabel == true);
      console.warn('questionList', val);
      return val;
    } else {
      return [];
    }
  }

}
