export class SalutationType {
    id: string;
    salutationName: string;
    version: number;

    clear(): void {
        this.id = '';
        this.salutationName = '';
        this.version = 0;
    }
}