import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionnaireRequestAccess } from '../_models/questionnaire-request-access.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class QuestionnaireRequestAccessService {

  constructor(private http: HttpClient) {
  }

  getAllQuestionnaireRequestAccess() {
    return this.http.get<QuestionnaireRequestAccess[]>(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}`);
  }

  getQuestionnaireRequestAccessById(id: string) {
    return this.http.get<QuestionnaireRequestAccess>(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}/${id}`);
  }

  updateQuestionnaireRequestAccess(element: QuestionnaireRequestAccess) {
    return this.http.put(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}`, element);
  }

  deleteQuestionnaireRequestAccess(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}`, options);
  }

  createQuestionnaireRequestAccess(probandId: string, encounterId: string, questionnaireId: string) {
    return this.http.get(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}/createRequestAccess/${probandId}/${encounterId}/${questionnaireId}`);
  }

  grantQuestionnaireRequestAccess(id: string) {
    return this.http.get(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}/grantEditRequestAccess/${id}`);
  }


}
