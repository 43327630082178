import { Organisation } from '../../auth';

export class Study {
    id: string;
    studyName: string;
    description: string;
    status: string;
    organisation: Organisation;
    studySequence: number;

    clear(): void {
        this.id = '';
        this.studyName = ''
        this.description = '';
        this.status = 'Approved';
        this.organisation = new Organisation();
        this.organisation.clear();
        this.studySequence = 0;
    }
}