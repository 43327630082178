import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Questionnaire } from 'src/app/core/masters/_models/questionnaire.model';
import { Observable, Subscription, of } from 'rxjs';
import { QuestionnaireService } from 'src/app/core/masters/_services/questionnaire.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { currentUserOrganisation, Organisation } from 'src/app/core/auth';

@Component({
  selector: 'kt-questionnaire-edit',
  templateUrl: './questionnaire-edit.component.html',
  styleUrls: ['./questionnaire-edit.component.scss']
})
export class QuestionnaireEditComponent implements OnInit {

  questionnaireForm: FormGroup;
  organisation: Organisation;
  questionnaire: Questionnaire;
  questionnaire$: Observable<Questionnaire>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  private subscriptions: Subscription[] = [];

  constructor(private _questionnaireService: QuestionnaireService,
    public dialogRef: MatDialogRef<QuestionnaireEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {

    this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    this.questionnaire$ = of(this.data.item);

    const elementSubscription = this.questionnaire$.subscribe(res => {
      if (!res) {
        return;
      }

      this.questionnaire = new Questionnaire();
      this.questionnaire = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(elementSubscription);
  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.questionnaireForm = this.FB.group({
      questionnaireName: [this.questionnaire.questionnaireName, Validators.required],
      critieria: [this.questionnaire.critieria, Validators.required],
      sortOrder: [this.questionnaire.sortOrder, Validators.required],
    });
  }

  prepareData(): Questionnaire {
    const controls = this.questionnaireForm.controls;
    const _data = new Questionnaire();
    _data.clear();
    _data.id = this.questionnaire.id;
    _data.questionnaireName = controls.questionnaireName.value;
    _data.critieria = controls.critieria.value;
    _data.sortOrder = controls.sortOrder.value;
    _data.organisation.id = this.organisation?.id;
    return _data;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.questionnaireForm.controls;
    /** check form */
    if (this.questionnaireForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedelement = this.prepareData();
    if (editedelement.id !== '') {
      this.updateQuestionnaire(editedelement);
    } else {
      this.createQuestionnaire(editedelement);
    }
  }


  updateQuestionnaire(_dataElement: Questionnaire) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._questionnaireService.updateQuestionnaire(_dataElement)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _dataElement,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createQuestionnaire(_dataElement: Questionnaire) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._questionnaireService.createQuestionnaire(_dataElement)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _dataElement,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.questionnaire && this.questionnaire.id) {
      return `Edit questionnaire - '${this.questionnaire.questionnaireName}'`;
    }
    return 'New questionnaire';
  }


}
