<div class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">

    <div class="card-header" *ngIf="center">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form">

        <div class="card-body" *ngIf="center">
            <form class="form" [formGroup]="centerForm">
                <mat-dialog-content>

                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                        (close)="onAlertClose($event)">
                        Oh snap! Change a few things up and try submitting again.
                    </kt-alert>

                    <div class="row">

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter center name" formControlName="nameOfCenter" />
                                <mat-error>Center name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Center name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter center code" formControlName="centerCode" />
                                <mat-error>Center code is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Center code</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row  form-group">

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter center address" formControlName="centerAddress" />
                                <mat-error>Center adress is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Center <address></address></strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter email" formControlName="emailAddress" />
                                <mat-error>Email is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Email</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row form-group">

                        <div class="col-lg-12 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select location" formControlName="locationId">
                                    <mat-option *ngFor="let item of location$ | async" value="{{ item.id }}">
                                        {{item.nameOfLocation}}</mat-option>
                                </mat-select>
                                <mat-error>Location
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Location</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row  form-group">

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter primary contact number"
                                    formControlName="primaryContactNumber" />
                                <mat-error>Primary contact number is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Primary contact number <address></address></strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter primary mobile number"
                                    formControlName="primaryMobileNumber" />
                                <mat-error> Primary mobile number is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong> Primary mobile number</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>


                </mat-dialog-content>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div>
                    <button type="button" mat-raised-button [mat-dialog-close]="null" cdkFocusInitial
                        matTooltip="Cancel changes">
                        Cancel
                    </button>&nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="viewLoading" matTooltip="Save changes">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>