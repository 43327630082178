import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionMaster } from '../_models/question-master.model';
import { environment } from 'src/environments/environment';

@Injectable()

export class QuestionMasterService {


  constructor(private http: HttpClient) {
  }

  getAllQuestions() {
    return this.http.get<QuestionMaster[]>(`${environment.host}${environment.apiUrls.questionMaster}`);
  }

  getQuestionById(id: string) {
    return this.http.get<QuestionMaster>(`${environment.host}${environment.apiUrls.questionMaster}/${id}`);
  }

  createQuestion(element: QuestionMaster) {
    return this.http.post(`${environment.host}${environment.apiUrls.questionMaster}`, element);
  }

  updateQuestion(element: QuestionMaster) {
    return this.http.put(`${environment.host}${environment.apiUrls.questionMaster}`, element);
  }

  deletQuestion(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.questionMaster}`, options);
  }
}
