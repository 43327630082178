<section class="cart cart_top">
  <div class="container">
    <div class="page_taitle d-flex align-items-center">
      <h2>Reset Password</h2>
    </div>
  </div>
</section>

<div class="row">
  <div class="col-md-8 offset-md-2">
    <p>&nbsp;</p>
  </div>
</div>
<div class="row">
  <div class="col-md-4 offset-md-2">
    <div class="alert alert-light" role="alert" *ngIf="!isTokenValid  && !userId">
      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 fa-9x">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
          class=""></path>
      </svg>

      {{ tokenMessage }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="error">
      password generation Failed 
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="isTokenValid || userId">
      <div class="form-group">
        <input type="password" id="pwd" name="password" class="form-control" formControlName="password"
          placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div id="pwdhider" class="" (click)="showHidePwd()"></div>

        <div *ngIf="form.get('password').errors && form.get('confirm_password').errors.required" class="text-danger">
          <span class="font_size_14px pt-2">Password is required</span>
        </div>
        <div *ngIf="form.get('password').errors && form.get('password').errors?.minlength" class="text-danger">
          <span class="font_size_14px pt-2">Must be at least 6 characters long</span>
        </div>
        <div *ngIf="form.get('password').errors && form.get('password').errors?.maxlength" class="text-danger">
          <span class="font_size_14px pt-2">Can be max 12 characters long</span>
        </div>
        <div *ngIf="form.get('password').errors && form.get('password').errors?.pattern" class="text-danger">
          <span class="font_size_14px pt-2">
            Your password must be lower case,upper case,number,special character and cannot use blank space
          </span>
        </div>

      </div>

      <div class="form-group">
        <input type="password" name="confirm_password" id="cnfrmpwd" class="form-control"
          formControlName="confirm_password" placeholder="Confirm Password"
          [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" required />
        <div></div>

        <div *ngIf="form.get('password').value && form.get('confirm_password')?.errors?.passwordMismatch">
          <span class="font_size_14px pt-2 text-danger"  *ngIf="!form.get('confirm_password').value">Confirm Password required</span>
          <span class="font_size_14px pt-2 text-danger" *ngIf="form.get('confirm_password').value">Passwords must match</span>
        </div>
        <div *ngIf="form.get('confirm_password').errors && form.get('confirm_password').errors.required">
          <span class="font_size_14px pt-2 text-danger">Confirm Password required</span>
        </div>

      </div>

      <div class="form-group">
        <button type="submit" [disabled]="form.invalid" class="btn btn-primary w-100">submit</button>
        <!-- <button class="cmn_btn">send</button> -->
      </div>
    </form>
  </div>
</div>
<!--
<div class="row">
    <div class="col-md-4 offset-md-2">
      <form [formGroup]="resetPwdForm" (ngSubmit)="resetPassword()">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            formControlName="newPassword"
            placeholder="New Password"
            [ngClass]="{ 'is-invalid': resetpwdsubmitted && resetpwdcontrols.email.errors }"/>

          <div *ngIf="resetpwdsubmitted && resetpwdcontrols.email.errors" class="invalid-feedback">
            <div *ngIf="resetpwdcontrols.email.errors.required">Email is required (like:John@test.com)</div>
            <div *ngIf="resetpwdsubmitted && resetpwdcontrols.email.errors.pattern">
              <span class="text-danger">Email must be a valid email address(like:John@test.com)</span>
            </div>
          </div>
        </div>
        <div class="form-group">
            <input
            type="text"
            class="form-control"
            formControlName="retypePassword"
            placeholder="Re-type Password"
            [ngClass]="{ 'is-invalid': resetpwdsubmitted && resetpwdcontrols.email.errors }"/>
        </div>
        <div class="form-group">
          <button class="cmn_btn">send</button>
        </div>
      </form>
    </div>
  </div>-->