<div class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">

    <div class="card-header" *ngIf="question">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form">

        <div class="card-body" *ngIf="question">
            <form class="form " [formGroup]="questionForm">
                <mat-dialog-content>

                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                        (close)="onAlertClose($event)">
                        Oh snap! Change a few things up and try submitting again.
                    </kt-alert>

                    <div class="row">

                        <div class="col-lg-12">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter question name" formControlName="questionName" />
                                <mat-error>Question name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>question name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-lg-12">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter question value" formControlName="questionValues" />
                                <mat-error>Question values is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>question value</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select data element" formControlName="dataElementId">
                                    <mat-option *ngFor="let item of dataElement$ | async" value="{{ item.id }}">
                                        {{item.elementName}}</mat-option>
                                </mat-select>
                                <mat-error>Data element
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Date element</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter description" formControlName="description" />
                                <mat-error>Description is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>description</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row form-group">

                        <div class="col-lg-6">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter instructions" formControlName="instructions" />
                                <mat-error>Instructions is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>instructions</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter special instructions"
                                    formControlName="specialInstructions" />
                                <mat-error>Special Instructions is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>special instructions</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                </mat-dialog-content>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div>
                    <button type="button" mat-raised-button [mat-dialog-close]="null" cdkFocusInitial
                        matTooltip="Cancel changes">
                        Cancel
                    </button>&nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="viewLoading" matTooltip="Save changes">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>