import { Component, OnInit, Inject } from '@angular/core';
import { LocationService } from '../../../../../../app/core/masters/_services/location.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '../../../../../../app/core/masters/_models/location.model';
import { Observable, of, Subscription } from 'rxjs';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { currentUserOrganisation, Organisation } from 'src/app/core/auth';

@Component({
  selector: 'kt-locations-edit',
  templateUrl: './locations-edit.component.html',
  styleUrls: ['./locations-edit.component.scss']
})
export class LocationsEditComponent implements OnInit {
  locationForm: FormGroup;
  location: Location;
  organisation: Organisation;
  location$: Observable<Location>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  private subscriptions: Subscription[] = [];

  constructor(private _locationService: LocationService,
    public dialogRef: MatDialogRef<LocationsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    this.location$ = of(this.data.item);

    const locationSubscription = this.location$.subscribe(res => {
      if (!res) {
        return;
      }

      this.location = new Location();
      this.location = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(locationSubscription);
  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.locationForm = this.locationFB.group({
      nameOfLocation: [this.location.nameOfLocation, Validators.required],
      locationCode: [this.location.locationCode, Validators.required],
    });
  }

  prepareLocation(): Location {
    const controls = this.locationForm.controls;
    const _location = new Location();
    _location.clear();
    _location.id = this.location.id;
    _location.nameOfLocation = controls.nameOfLocation.value;
    _location.locationCode = controls.locationCode.value;
    _location.organisation.id = this.organisation?.id;
    return _location;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.locationForm.controls;
    /** check form */
    if (this.locationForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedLocation = this.prepareLocation();
    if (editedLocation.id !== '') {
      this.updateLocation(editedLocation);
    } else {
      this.createLocation(editedLocation);
    }
  }


  updateLocation(_location: Location) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._locationService.updateLocation(_location)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _location,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createLocation(_location: Location) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._locationService.createLocation(_location)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _location,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.location && this.location.id) {
      return `Edit location - '${this.location.nameOfLocation}'`;
    }
    return 'New Location';
  }

}

