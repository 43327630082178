// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MastersModule } from './masters/masters.module';
//import { QuestionnaireRequestAccessListComponent } from './questionnaire-request-access/questionnaire-request-access-list/questionnaire-request-access-list.component';

@NgModule({
  //declarations: [QuestionnaireRequestAccessListComponent],
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    UserManagementModule,
    MastersModule
  ],
  providers: []
})
export class PagesModule {
}
