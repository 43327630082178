// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Observable, of, Subscription } from 'rxjs';
// Lodash
import { each, find, some } from 'lodash';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// Services and Models
import {
	Role,
	Permission,
	selectRoleById,
	RoleUpdated,
	selectAllPermissions,
	selectLastCreatedRoleId,
	RoleOnServerCreated,
	selectRolesActionLoading
} from '../../../../../core/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LayoutUtilsService } from '../../../../../../app/core/_base/crud';

@Component({
	selector: 'kt-role-edit-dialog',
	templateUrl: './role-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class RoleEditDialogComponent implements OnInit, OnDestroy {
	// Public properties
	role: Role;
	role$: Observable<Role>;
	hasFormErrors = false;
	viewLoading = false;
	loadingAfterSubmit = false;
	allPermissions$: Observable<Permission[]>;
	rolePermissions: Permission[] = [];
	roleForm: FormGroup;

	// Private properties
	private componentSubscriptions: Subscription;
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<RoleEditDialogComponent>
	 * @param data: any
	 * @param store: Store<AppState>
	 */
	constructor(public dialogRef: MatDialogRef<RoleEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private store: Store<AppState>,
		private roleFB: FormBuilder,
		private layoutUtilsService: LayoutUtilsService,

	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.data.roleId) {
			this.role$ = this.store.pipe(select(selectRoleById(this.data.roleId)));
		} else {
			const newRole = new Role();
			newRole.clear();
			this.role$ = of(newRole);
		}

		const roleSubscription = this.role$.subscribe(res => {
			if (!res) {
				return;
			}

			this.role = new Role();
			this.role = res;

			this.createForm();
			this.allPermissions$ = this.store.pipe(select(selectAllPermissions));
			// this.loadPermissions();
		});
		this.subscriptions.push(roleSubscription);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
		this.subscriptions.forEach(e => e.unsubscribe());
	}

	/**
	 * Create form
	 */
	createForm() {
		this.roleForm = this.roleFB.group({
			title: [this.role.title, Validators.required],
			roleName: [this.role.roleName, Validators.required],
			roleDescription: [this.role.roleDescription, Validators.required],
		});
	}

	/**
	 * Returns role for save
	 */
	prepareRole(): Role {
		const controls = this.roleForm.controls;
		const _role = new Role();
		_role.id = this.role.id;
		_role.title = controls.title.value;
		_role.isCoreRole = this.role.isCoreRole;
		_role.roleName = controls.roleName.value;
		_role.roleDescription = controls.roleDescription.value;
		return _role;
	}

	/**
	 * Save data
	 */
	onSubmit() {
		this.loadingAfterSubmit = false;

		this.hasFormErrors = false;
		const controls = this.roleForm.controls;
		/** check form */
		if (this.roleForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedRole = this.prepareRole();
		if (editedRole.id !== '') {
			this.updateRole(editedRole);
		} else {
			this.createRole(editedRole);
		}
	}

	/**
	 * Update role
	 *
	 * @param _role: Role
	 */
	updateRole(_role: Role) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
		/* Server loading imitation. Remove this on real code */
		const updateRole: Update<Role> = {
			id: this.role.id,
			changes: _role
		};
		this.store.dispatch(new RoleUpdated({
			partialrole: updateRole,
			role: _role
		}));

		this.componentSubscriptions = this.store.pipe(
			select(selectRolesActionLoading)
		).subscribe(res => {
			if (!res) {
				this.viewLoading = false;
				this.dialogRef.close({
					_role,
					isEdit: true
				});
			}
		});
	}

	/**
	 * Create role
	 *
	 * @param _role: Role
	 */
	createRole(_role: Role) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
		this.store.dispatch(new RoleOnServerCreated({ role: _role }));
		this.componentSubscriptions = this.store.pipe(
			select(selectLastCreatedRoleId)
		).subscribe(res => {
			if (!res) {
				return;
			}

			this.viewLoading = false;
			this.dialogRef.close({
				_role,
				isEdit: false
			});
		});
	}

	/**
	 * Close alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	/** UI */
	/**
	 * Returns component title
	 */
	getTitle(): string {
		if (this.role && this.role.id) {
			// tslint:disable-next-line:no-string-throw
			return `Edit role '${this.role.roleName}'`;
		}

		// tslint:disable-next-line:no-string-throw
		return 'New role';
	}


}
