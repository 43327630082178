import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Proband, ProbandRespone } from '../_models/proband.model';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Encounter } from '../_models/encounter.model';

@Injectable()
export class ProbandService {

  selectedValue: {
    encounter: Encounter;
    proband: Proband;
  }
  constructor(private http: HttpClient) {

  }

  getAllProband(start, end, searchTerm, currentPage, pageSize) {
    return this.http.get<ProbandRespone>(`${environment.host}${environment.apiUrls.proband}` + "?start=" + start + "&end=" + end +
      "&page="+currentPage+"&size="+pageSize+"&searchTerm=" + searchTerm);
  }

  getProbandById(id: string) {
    return this.http.get<Proband>(`${environment.host}${environment.apiUrls.proband}/${id}`);
  }

  createProband(element: Proband) {
    return this.http.post(`${environment.host}${environment.apiUrls.proband}`, element);
  }

  updateProband(element: Proband) {
    return this.http.put(`${environment.host}${environment.apiUrls.proband}`, element);
  }

  deletProband(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.proband}`, options);
  }

  getSelectedEncounter() {
    return this.selectedValue;
  }

  setSelectedEncounter(config: Encounter, proband: Proband) {
    this.selectedValue = {
      encounter: config,
      proband: proband
    }
  }
}
