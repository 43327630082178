import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Section } from 'src/app/core/masters/_models/section.model';
import { Observable, Subscription, of } from 'rxjs';
import { Questionnaire } from 'src/app/core/masters/_models/questionnaire.model';
import { SectionService } from 'src/app/core/masters/_services/section.service';
import { QuestionnaireService } from 'src/app/core/masters/_services/questionnaire.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';

@Component({
  selector: 'kt-section-edit',
  templateUrl: './section-edit.component.html',
  styleUrls: ['./section-edit.component.scss']
})
export class SectionEditComponent implements OnInit {


  sectionForm: FormGroup;
  section: Section;
  section$: Observable<Section>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  questionnaire$: Observable<Questionnaire[]>;

  private subscriptions: Subscription[] = [];

  constructor(private _sectionService: SectionService,
    private _questionnaireService: QuestionnaireService,
    public dialogRef: MatDialogRef<SectionEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
  ) { }

  ngOnInit(): void {

    this.section$ = of(this.data.item);

    const elementSubscription = this.section$.subscribe(res => {
      if (!res) {
        return;
      }

      this.section = new Section();
      this.section = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(elementSubscription);
    this.questionnaire$ = this._questionnaireService.getAllQuestionnaire();

  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.sectionForm = this.FB.group({
      sectionName: [this.section.sectionName, Validators.required],
      displayOrder: [this.section.displayOrder, Validators.required],
      questionnaireId: [this.section.questionnaire?.id, Validators.required]

    });
  }

  prepareData(): Section {
    const controls = this.sectionForm.controls;
    const _data = new Section();
    _data.clear();
    _data.id = this.section.id;
    _data.questionnaire.id = controls.questionnaireId.value;
    _data.sectionName = controls.sectionName.value;
    _data.displayOrder = controls.displayOrder.value;

    return _data;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.sectionForm.controls;
    /** check form */
    if (this.sectionForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedelement = this.prepareData();
    if (editedelement.id !== '') {
      this.updateSection(editedelement);
    } else {
      this.createSection(editedelement);
    }
  }


  updateSection(_dataElement: Section) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._sectionService.updateSection(_dataElement)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _dataElement,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createSection(_dataElement: Section) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._sectionService.createSection(_dataElement)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _dataElement,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.section && this.section.id) {
      return `Edit section - '${this.section.sectionName}'`;
    }
    return 'New section';
  }


}
