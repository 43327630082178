<div class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">

    <div class="card-header" *ngIf="role">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form">

        <div class="card-body" *ngIf="role">
            <form class="form form-group-seperator-dashed" [formGroup]="roleForm">
                <mat-dialog-content>

                    <!-- <div class="card-body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div> -->
                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                        (close)="onAlertClose($event)">
                        Oh snap! Change a few things up and try submitting again.
                    </kt-alert>

                    <div class="mb-2">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Enter Title" formControlName="title" />
                            <mat-error>Title is
                                <strong>required</strong>
                            </mat-error>
                            <mat-hint align="start">Please enter
                                <strong>Title</strong>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="separator separator-dashed my-6"></div>

                    <div class="row">

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter role name" formControlName="roleName" />
                                <mat-error>Role name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>role name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter role description"
                                    formControlName="roleDescription" />
                                <mat-error>Role description is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>role description</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                </mat-dialog-content>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div>
                    <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
                        matTooltip="Cancel changes">
                        Cancel
                    </button>&nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="viewLoading" matTooltip="Save changes">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>