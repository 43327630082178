import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Encounter } from '../_models/encounter.model';

@Injectable()
export class EncounterService {

  constructor(private http: HttpClient) {
  }

  getAllEncounter() {
    return this.http.get<Encounter[]>(`${environment.host}${environment.apiUrls.encounter}`);
  }

  getEncounterById(id: string) {
    return this.http.get<Encounter>(`${environment.host}${environment.apiUrls.encounter}/${id}`);
  }

  createEncounter(element: Encounter) {
    return this.http.post(`${environment.host}${environment.apiUrls.encounter}`, element);
  }

  updateEncounter(element: Encounter) {
    return this.http.put(`${environment.host}${environment.apiUrls.encounter}`, element);
  }

  deletEncounter(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.encounter}`, options);
  }
}
