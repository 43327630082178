export class MenuConfig {
  public defaults: any = {
    ROLE_SUPERADMIN: {
      header: {
        self: {},
        items: [
          {
            title: 'Dashboards',
            root: true,
            alignment: 'left',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
          },
          {
            title: 'Comments',
            root: true,
            alignment: 'left',
            page: '/comment/comment',
            //translate: 'MENU.COMMENT',
          },
          {
            title: 'Masters',
            root: true,
            alignment: 'left',
            toggle: 'click',
            submenu: [
              {
                title: 'Masters',
                bullet: 'dot',
                icon: 'flaticon-interface-7',
                submenu: [
                  {
                    title: 'Location',
                    page: '/masters/location',
                  },
                  {
                    title: 'Study',
                    page: '/masters/study',
                  },
                  {
                    title: 'Proforma',
                    page: '/masters/encounter',
                  },
                  {
                    title: 'Patient',
                    page: '/masters/Patient',
                  },
                  {
                    title: 'Salutation Type',
                    page: '/masters/salutation-type',
                  },
                  {
                    title: 'Data Collection Center',
                    page: '/masters/data-collection-center',
                  },
                  {
                    title: 'Data Element',
                    page: '/masters/data-element',
                  },
                  {
                    title: 'Question Master',
                    page: '/masters/question-master',
                  },
                  {
                    title: 'Questionnaire',
                    page: '/masters/questionnaire',
                  },
                  {
                    title: 'Section',
                    page: '/masters/section',
                  }
                ]
              }
            ]
          },
          {
            title: 'User Management',
            root: true,
            alignment: 'left',
            toggle: 'click',
            submenu: [
              {
                title: 'Users',
                page: '/user-management/users'
              },
              {
                title: 'Roles',
                page: '/user-management/roles'
              },
              {
                title: 'Organisations',
                page: '/user-management/organisations'
              }
            ]
          },
          {
            title: 'Patients',
            root: true,
            alignment: 'left',
            page: '/masters/Patient',
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
      aside: {
        self: {},
        items: [
          {
            title: 'Dashboard',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
            bullet: 'dot',
          },
          {
            title: 'Comment',
            root: true,
            icon: 'flaticon2-chat',
            page: '/comment/comment',
            //translate: 'MENU.DASHBOARD',
            bullet: 'dot',
          },
          { section: 'Masters' },
          {
            title: 'Masters',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            submenu: [
              {
                title: 'Location',
                page: '/masters/location',
              },
              {
                title: 'Study',
                page: '/masters/study',
              },
              {
                title: 'Proforma',
                page: '/masters/encounter',
              },
              {
                title: 'Patient',
                page: '/masters/Patient',
              },
              {
                title: 'Salutation Type',
                page: '/masters/salutation-type',
              },
              {
                title: 'Data Collection Center',
                page: '/masters/data-collection-center',
              },
              {
                title: 'Data Element',
                page: '/masters/data-element',
              },
              {
                title: 'Question Master',
                page: '/masters/question-master',
              },
              {
                title: 'Questionnaire',
                page: '/masters/questionnaire',
              },
              {
                title: 'Section',
                page: '/masters/section',
              }
            ]
          },
          {
            title: 'Mappings',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            submenu: [
              {
                title: 'Study - Center',
                page: '/mappings/study-to-center',
              },
              {
                title: 'Question - Section',
                page: '/mappings/question-to-section',
              },
              {
                title: 'Questionnaire - Study',
                page: '/mappings/questionnaire-to-study',
              },
              {
                title: 'Patient - Study',
                page: '/mappings/Patient-to-study',
              },
              {
                title: 'Question - SubQuestion',
                page: '/mappings/question-to-subquestion',
              },
              {
                title: 'Encounter - Questionnaire',
                page: '/mappings/encounter-to-questionnaire',
              },
            ]
          },
          { section: 'Configuration' },
          {
            title: 'User Management',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            submenu: [
              {
                title: 'Users',
                page: '/user-management/users',
              },
              {
                title: 'Roles',
                page: '/user-management/roles',
              },
              {
                title: 'Organisations',
                page: '/user-management/organisations',
              }
            ]
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
    },
    ROLE_LEAD: {
      header: {
        self: {},
        items: [
          {
            title: 'Dashboards',
            root: true,
            alignment: 'left',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
          },
          {
            title: 'Patients',
            root: true,
            alignment: 'left',
            page: '/masters/Patient',
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
      aside: {
        self: {},
        items: [
          {
            title: 'Dashboard',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
            bullet: 'dot',
          },
          {
            title: 'Masters',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            submenu: [
              {
                title: 'Location',
                page: '/masters/location',
              },
              {
                title: 'Data Collection Center',
                page: '/masters/data-collection-center',
              }
            ]
          },
          { section: 'Reports' },
          {
            title: 'Consolidated Report',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/reports/consolidate'
          },
          { section: 'Configuration' },
          {
            title: 'User Management',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            submenu: [
              {
                title: 'Users',
                page: '/user-management/users',
              },
              {
                title: 'Roles',
                page: '/user-management/roles',
              }
            ]
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
    },
    ROLE_SITE_ADMIN: {
      header: {
        self: {},
        items: [
          {
            title: 'Dashboards',
            root: true,
            alignment: 'left',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
          },
          {
            title: 'Patients',
            root: true,
            alignment: 'left',
            page: '/masters/Patient',
          },
        ]
      },
      aside: {
        self: {},
        items: [
          {
            title: 'Dashboard',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
            bullet: 'dot',
          },
          {
            title: 'Patient',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/masters/Patient'
          },
          {
            title: 'Comments',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            alignment: 'left',
            page: '/comment/comment',
            //translate: 'MENU.COMMENT',
          },
          {
            title: 'Profoma Edit Access Requests',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/questionnaire-request-access'
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
    },
    ROLE_JUNIOR_CRC: {
      header: {
        self: {},
        items: [
          {
            title: 'Dashboards',
            root: true,
            alignment: 'left',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
          },
          {
            title: 'Patients',
            root: true,
            alignment: 'left',
            page: '/masters/Patient',
          },
          {
            title: 'Patients',
            root: true,
            alignment: 'left',
            page: '/',
          },
          {
            title: 'Comments',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            alignment: 'left',
            page: '/comment/comment',
            //translate: 'MENU.COMMENT',
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
      aside: {
        self: {},
        items: [
          {
            title: 'Dashboard',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
            bullet: 'dot',
          },
          {
            title: 'Patient',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/masters/Patient'
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      }
    },      //Center data entry person
    ROLE_SENIOR_CRC: {
      header: {
        self: {},
        items: [
          {
            title: 'Dashboards',
            root: true,
            alignment: 'left',
            page: '/dashboard',
            translate: 'MENU.DASHBOARD',
          },
          {
            title: 'Patients',
            root: true,
            alignment: 'left',
            page: '/masters/Patient',
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      },
      aside: {
        self: {},
        items: [
          {
            title: 'Dashboard',
            root: true,
            icon: 'flaticon2-architecture-and-city',
            page: '/dashboard',
            bullet: 'dot',
          },
          {
            title: 'Patient',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-brow112ser-2',
            page: '/masters/Patient'
          },
          {
            title: 'Profoma Edit Access Requests',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/questionnaire-request-access'
          },
          {
            title: 'Training Modules',
            root: true,
            bullet: 'dot',
            icon: 'flaticon2-browser-2',
            page: '/profile/site-help'
          }
        ]
      }
    }
  }
  public get configs(): any {
    return this.defaults;
  }
}
