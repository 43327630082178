import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Encounter } from 'src/app/core/masters/_models/encounter.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { EncounterService } from 'src/app/core/masters/_services/encounter.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from 'src/app/core/_base/layout';
import { EncounterEditComponent } from '../encounter-edit/encounter-edit.component';

@Component({
  selector: 'kt-encounter-list',
  templateUrl: './encounter-list.component.html',
  styleUrls: ['./encounter-list.component.scss']
})
export class EncounterListComponent implements OnInit {


  // Table fields
  dataSource = new MatTableDataSource<Encounter>();
  displayedColumns = ['select', 'organisation', 'proband', 'location', 'questionnaire', 'study', 'questionnaireStatus', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Encounter>(true, []);
  encounterResult: Encounter[] = [];

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _encounterService: EncounterService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Proformas');
    this.getAllEncounters();
  }

  getAllEncounters() {
    this.isLoading = true;
    this._encounterService.getAllEncounter()
      .subscribe(res => {
        this.encounterResult = res;
        this.dataSource = new MatTableDataSource<Encounter>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.encounterResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.encounterResult.length) {
      this.selection.clear();
    } else {
      this.encounterResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.questionnaireStatus,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(item: Encounter) {
    const _saveMessage = `Proforma successfully has been saved.`;
    const _messageType = item.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(EncounterEditComponent, { data: { item: item }, width: '50%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllEncounters();
    });
  }


  add() {
    const newItem = new Encounter();
    newItem.clear(); // Set all defaults fields
    this.edititem(newItem);
  }

  deleteitem(_item: Encounter) {
    const _title = 'Proforma';
    const _description = 'Are you sure to permanently delete this proforma?';
    const _waitDesciption = 'Proforma is deleting...';
    const _deleteMessage = `Proforma has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._encounterService.deletEncounter(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllEncounters();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
