import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionCommentService {


  constructor(private http: HttpClient) {
  }
  addComment(comment) {
    return this.http.post(`${environment.host}${environment.apiUrls.questionComments}`, comment);
  }

  getAllComments(commentStatus) {
    return this.http.get(`${environment.host}${environment.apiUrls.questionComments}/allProbandQuestionnariesCommentsByStatus/${commentStatus}`);
  }

  resolveComment(comment) {
    return this.http.put(`${environment.host}${environment.apiUrls.questionComments}`, comment);
  }

  getCommentsByQuestionMappingId(probandStudyQuestionnaireResponseId: any, questionMappingId: any) {
    return this.http.get(`${environment.host}${environment.apiUrls.questionComments}/allCommentsForQuestion/${probandStudyQuestionnaireResponseId}/${questionMappingId}`, probandStudyQuestionnaireResponseId);
  }
}
