// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective
} from './_base/layout';
import { LocationService } from './masters/_services/location.service';
import { StudyService } from './masters/_services/study.service';
import { DataElementsService } from './masters/_services/data-elements.service';
import { QuestionMasterService } from './masters/_services/question-master.service';
import { SalutationTypeService } from './masters/_services/salutation-type.service';
import { DataCollectionCenterService } from './masters/_services/data-collection-center.service';
import { ProbandService } from './masters/_services/proband.service';
import { QuestionnaireService } from './masters/_services/questionnaire.service';
import { QuestionnaireRequestAccessService } from './masters/_services/questionnaire-request-access.service';
import { EncounterService } from './masters/_services/encounter.service';
import { SectionService } from './masters/_services/section.service';
import { QuestionMappingService } from './mappings/_services/question-mapping.service';
import { MappingsService } from './mappings/_services/mappings.service';
import { IsScoreLabelPipePipe } from './_base/layout/pipes/is-score-label-pipe.pipe';
import { QuestionCommentService } from './masters/_services/question-comment.service';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    IsScoreLabelPipePipe,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    IsScoreLabelPipePipe
  ],
  providers: [LocationService,
    StudyService,
    DataElementsService,
    QuestionMasterService,
    SalutationTypeService,
    DataCollectionCenterService,
    ProbandService,
    QuestionnaireService,
    EncounterService,
    SectionService,
    IsScoreLabelPipePipe,
    QuestionnaireRequestAccessService,
    QuestionMappingService,
    MappingsService,
    QuestionCommentService
  ]
})
export class CoreModule {
}
