import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuestionMaster } from '../../../../../../app/core/masters/_models/question-master.model';
import { Observable, Subscription, of } from 'rxjs';
import { QuestionMasterService } from '../../../../../../app/core/masters/_services/question-master.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { DataElementsService } from 'src/app/core/masters/_services/data-elements.service';
import { DataElement } from 'src/app/core/masters/_models/data-element.model';

@Component({
  selector: 'kt-question-master-edit',
  templateUrl: './question-master-edit.component.html',
  styleUrls: ['./question-master-edit.component.scss']
})
export class QuestionMasterEditComponent implements OnInit {
  questionForm: FormGroup;
  question: QuestionMaster;
  question$: Observable<QuestionMaster>;
  dataElement$: Observable<DataElement[]>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  private subscriptions: Subscription[] = [];

  constructor(private _questionService: QuestionMasterService,
    private _dataElementService: DataElementsService,
    public dialogRef: MatDialogRef<QuestionMasterEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private questionFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
  ) { }

  ngOnInit(): void {

    this.question$ = of(this.data.item);

    const dataElementSubscription = this.question$.subscribe(res => {
      if (!res) {
        return;
      }

      this.question = new QuestionMaster();
      this.question = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(dataElementSubscription);
    this.dataElement$ = this._dataElementService.getAllDataElements();

  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.questionForm = this.questionFB.group({
      questionName: [this.question.questionName, Validators.required],
      questionValues: [this.question.questionValues],
      description: [this.question.description],
      instructions: [this.question.instructions],
      specialInstructions: [this.question.specialInstructions],
      dataElementId: [this.question.dataElementsType?.id, Validators.required]
    });
  }

  prepareData(): QuestionMaster {
    const controls = this.questionForm.controls;
    const _data = new QuestionMaster();
    _data.clear();
    _data.id = this.question.id;
    _data.questionName = controls.questionName.value;
    _data.questionValues = controls.questionValues.value;
    _data.description = controls.description.value;
    _data.instructions = controls.instructions.value;
    _data.specialInstructions = controls.specialInstructions.value;
    _data.dataElementsType.id = controls.dataElementId.value;
    return _data;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.questionForm.controls;
    /** check form */
    if (this.questionForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedData = this.prepareData();
    if (editedData.id !== '') {
      this.updateQuestion(editedData);
    } else {
      this.createQuestion(editedData);
    }
  }


  updateQuestion(_data: QuestionMaster) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._questionService.updateQuestion(_data)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _data,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createQuestion(_data: QuestionMaster) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._questionService.createQuestion(_data)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _data,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.question && this.question.id) {
      return `Edit Question - '${this.question.questionName}'`;
    }
    return 'New Question';
  }


}
