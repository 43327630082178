import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { QuestionMaster } from 'src/app/core/masters/_models/question-master.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { QuestionMasterService } from '../../../../../../app/core/masters/_services/question-master.service';
import { Subscription } from 'rxjs';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { QuestionMasterEditComponent } from '../question-master-edit/question-master-edit.component';

@Component({
  selector: 'kt-question-master-list',
  templateUrl: './question-master-list.component.html',
  styleUrls: ['./question-master-list.component.scss']
})
export class QuestionMasterListComponent implements OnInit {


  // Table fields
  dataSource = new MatTableDataSource<QuestionMaster>();;
  displayedColumns = ['select', 'questionName', 'description', 'dateElement', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<QuestionMaster>(true, []);
  questionResult: QuestionMaster[] = [];

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _questionMasterService: QuestionMasterService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Questions');
    this.getAllQuestions();
  }

  getAllQuestions() {
    this.isLoading = true;
    this._questionMasterService.getAllQuestions()
      .subscribe(res => {
        this.questionResult = res;
        this.dataSource = new MatTableDataSource<QuestionMaster>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.questionResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.questionResult.length) {
      this.selection.clear();
    } else {
      this.questionResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.questionName,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(item: QuestionMaster) {
    const _saveMessage = `Question has been saved successfully.`;
    const _messageType = item.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(QuestionMasterEditComponent, { data: { item: item }, width: '60%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllQuestions();
    });
  }


  add() {
    const newItem = new QuestionMaster();
    newItem.clear(); // Set all defaults fields
    this.edititem(newItem);
  }

  deleteitem(_item: QuestionMaster) {
    const _title = 'Question Master';
    const _description = 'Are you sure to permanently delete this question?';
    const _waitDesciption = 'Question is deleting...';
    const _deleteMessage = `Question has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._questionMasterService.deletQuestion(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllQuestions();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
