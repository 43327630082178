import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { Proband } from 'src/app/core/masters/_models/proband.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubheaderService, LayoutConfigService } from 'src/app/core/_base/layout';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { ProbandService } from 'src/app/core/masters/_services/proband.service';
import { SalutationTypeService } from 'src/app/core/masters/_services/salutation-type.service';
import { LocationService } from 'src/app/core/masters/_services/location.service';
import { DataCollectionCenterService } from 'src/app/core/masters/_services/data-collection-center.service';
import { StudyService } from 'src/app/core/masters/_services/study.service';
import { Observable, Subscription } from 'rxjs';
import { SalutationType } from 'src/app/core/masters/_models/salutation-type.model';
import { Location } from 'src/app/core/masters/_models/location.model';
import { DataCollectionCenter } from 'src/app/core/masters/_models/data-collection-center.model';
import { Study } from 'src/app/core/masters/_models/study.model';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { currentUserOrganisation, Organisation, currentUserRoles, User, currentUser, isCreateProbandAllowed } from 'src/app/core/auth';
import { MAT_DATE_FORMATS } from "@angular/material/core";
// import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'kt-proband-edit',
  templateUrl: './proband-edit.component.html',
  styleUrls: ['./proband-edit.component.scss'],
  providers: [
    //   { provide: LOCALE_ID, useValue: "en-IN" },
    //   { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    //   { provide: DatePipe }
  ]
})
export class ProbandEditComponent implements OnInit {
  proband: Proband;
  organisation: Organisation;
  oldProband: Proband;
  hasFormErrors = false;
  probandForm: FormGroup;
  salutationType$: Observable<SalutationType[]>;
  location$: Observable<Location[]>;
  center$: Observable<DataCollectionCenter[]>;
  study$: Observable<Study[]>;
  isCenteruserRole: boolean;
  isSuperAdminRole: boolean;
  loggedUser: User;

  private subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private FB: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private _probandService: ProbandService,
    private _salutationService: SalutationTypeService,
    private _locationService: LocationService,
    private _centerService: DataCollectionCenterService,
    private _studyService: StudyService,
    private store: Store<AppState>,
    // private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    const rolesSubscription = this.store.pipe(select(currentUserRoles))
      .subscribe(res => {
        if (res.length > 0) {
          let roles = new Array();
          res.forEach(element => {
            roles.push(Object.values(element)[0]);
          });
          roles.includes('ROLE_JUNIOR_CRC') || roles.includes('ROLE_SITE_ADMIN') ? this.isCenteruserRole = true : this.isCenteruserRole = false;
          roles.includes('ROLE_SUPERADMIN') ? this.isSuperAdminRole = true : this.isSuperAdminRole = false;
        }

      });

    this.store.pipe(select(currentUser)).subscribe(res => this.loggedUser = res);
    this.proband = new Proband();
    this.proband.clear();
    this.createForm();

    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this._probandService.getProbandById(id).subscribe(res => {
          this.proband = res;
          this.oldProband = Object.assign({}, this.proband);
          this.initProband();
        },
          (error) => {
            this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Update, 5000, true, true);
          });

      } else {

        this.oldProband = Object.assign({}, this.proband);
        this.initProband();

      }
    });

    this.salutationType$ = this._salutationService.getAllsalutationTypes();
    this.location$ = this._locationService.getAllLocations();
    this.center$ = this._centerService.getAllCenters();
    this.study$ = this._studyService.getAllStudy();

    this.subscriptions.push(routeSubscription);

  }


  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }


  createForm() {

    this.probandForm = this.FB.group({
      firstName: [this.proband.firstName, Validators.required],
      lastName: [this.proband.lastName, Validators.required],
      gender: [this.proband.gender.toString(), Validators.required],
      maritalStatus: [this.proband.maritalStatus,/* Validators.required*/],
      /*aadharId: [this.proband.aadharId],*/
      age: [this.proband.age, /*Validators.required*/],
      dateOfBirth: [this.proband.dateOfBirth],
      address1: [this.proband.address1],
      mobileNumber: [this.proband.mobileNumber, /*Validators.required*/],
      email: [this.proband.email, [Validators.email, /*Validators.required*/]],
      guardianName: [this.proband.guardianName],
      guardianPh: [this.proband.guardianPh],
      state: [this.proband.state],
      city: [this.proband.city],
      taluk: [this.proband.taluk],
      districtName: [this.proband.districtName],
      zip: [this.proband.zip],
      nationality: [this.proband.nationality],
      occupation: [this.proband.occupation],
      organisationId: [this.loggedUser?.organisation?.id],
      locationId: [this.loggedUser?.dataCollectionCenter?.location?.id,/* Validators.required*/],
      centerId: [this.loggedUser?.dataCollectionCenter?.id, /*Validators.required*/],
      studyId: [this.proband.study?.id, Validators.required],
      salutationTypeId: [this.proband.salutationType?.id, Validators.required],
      bloodGroup: [this.proband.bloodGroup]
    });

  }


  initProband() {
    this.createForm();
    if (this.proband.id === '') {
      this.subheaderService.setTitle('Create Patient');
      this.subheaderService.setBreadcrumbs([
        { title: 'Master', page: `masters` },
        { title: 'Patients', page: `masters/Patient` },
        { title: 'Create Patient', page: `masters/Patient/add` }
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit Patient');
    this.subheaderService.setBreadcrumbs([
      { title: 'Master', page: `masters` },
      { title: 'Patients', page: `masters/Patient` },
      { title: 'Edit Patient', page: `masters/Patient/edit`, queryParams: { id: this.proband.id } }
    ]);
  }

  onSumbit(withBack: boolean) {
    this.hasFormErrors = false;
    const controls = this.probandForm.controls;
    /** check form */
    if (this.probandForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    const editedelement = this.prepareData();
    console.warn(editedelement);

    if (editedelement.id !== '') {
      this.updateProband(editedelement);
    } else {
      this.addProband(editedelement);
    }
  }

  /**
     * Returns prepared data for save
     */
  prepareData(): Proband {
    const controls = this.probandForm.controls;
    const _proband = new Proband();
    _proband.clear();
    _proband.id = this.proband.id;
    _proband.firstName = controls.firstName.value;
    _proband.lastName = controls.lastName.value;
    _proband.gender = controls.gender.value;
    _proband.maritalStatus = controls.maritalStatus.value;
    /*_proband.aadharId = controls.aadharId.value;*/
    _proband.age = controls.age.value;
    _proband.dateOfBirth = moment(controls.dateOfBirth.value).format("YYYY-MM-DD");
    _proband.address1 = controls.address1.value;
    _proband.mobileNumber = controls.mobileNumber.value;
    _proband.email = controls.email.value;
    _proband.guardianName = controls.guardianName.value;
    _proband.guardianPh = controls.guardianPh.value;
    _proband.salutationType.id = controls.salutationTypeId.value;
    _proband.organisation.id = this.organisation?.id;
    _proband.location.id = controls.locationId.value;
    _proband.center.id = controls.centerId.value;
    _proband.study.id = controls.studyId.value;
    return _proband;
  }

  addProband(_proband: Proband) {
    this._probandService.createProband(_proband).subscribe(newId => {
      if (newId) {//
        const message = `New patient has been added successfully.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
        this.goBackWithId(newId['id']);
      }
    }, (error) => {
      this.layoutUtilsService.showActionNotification("Server error", MessageType.Create, 5000, true, true);
    });
  }

  updateProband(_proband: Proband) {

    const message = `Patient successfully has been updated.`;
    this._probandService.updateProband(_proband).subscribe(res => {
      if (res) {
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
        this.goBackWithId(_proband.id);
      }
    }, (error) => {
      this.layoutUtilsService.showActionNotification("Server error", MessageType.Create, 5000, true, true);
    });
  }

  /**
  * Redirect to list
  *
  */
  goBack() {
    const url = `/masters/Patient`;
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }

  goBackWithId(newId) {
    const url = `/dashboard/Patient/view/`+newId;
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }

  reset() {
    this.proband = Object.assign({}, this.oldProband);
    this.createForm();
    this.hasFormErrors = false;
    this.probandForm.markAsPristine();
    this.probandForm.markAsUntouched();
    this.probandForm.updateValueAndValidity();
  }



  getComponentTitle() {
    let result = 'Create Patient';
    if (!this.proband || !this.proband.id) {
      return result;
    }

    result = `Edit Patient - ${this.proband.firstName}`;
    return result;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

}
