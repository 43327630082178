<kt-portlet>

    <kt-portlet-header [title]="'Patient list'" [class]="'card-head-lg'">
        <ng-container ktPortletTools>
            <button [routerLink]="['../Patient/add']" mat-raised-button matTooltip="Create new patient"
                matTooltipClass="example-tooltip-red" color="primary" type="button"  *ngIf="isAddProbandRights" >
                <span>New Patient</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form kt-margin-b-30">

          <div class="row form-group">
              <div class="col-lg-4 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <mat-select id="ST"  (selectionChange)="applyFilter($event)" placeholder="Study">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let item of study$ | async" value="{{ item.id }}">
                      {{item.studyName}}</mat-option>
                  </mat-select>
                  <mat-error>
                    <strong> is Required </strong>
                  </mat-error>
                </mat-form-field>
              </div>
             <!-- <div class="col-lg-4 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input id="PI" matInput placeholder="Patient Id" (change)="applyPatientIdFilter($event)">
                </mat-form-field>
              </div>
              <div class="col-lg-4 kt-margin-bottom-20-mobile">
                <a href="javascript:;" class="btn btn-primary mr-2" color="primary" (click)="applyPatientIdFilter(true)"
                   mat-raised-button matTooltip="Save & Continue">
                  <span class="hidden-mobile">Save</span>
                </a>
              </div>-->

          </div>
            <!-- end::FILTERS -->


            <div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
                [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="form-group form-group-inline">
                        <div class="form-label form-label-no-wrap">
                            <label class="font-bold font-danger">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
                                {{ selection.selected.length }}
                            </label>
                        </div>
                        <div>
                            <button (click)="fetchitems()" mat-raised-button matTooltip="Fetch selected locations"
                                class="mat-button-mt-4">
                                <mat-icon>clear_all</mat-icon>
                                Fetch Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->


        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="patientId" matSortDirection="asc" matSortDisableClear>
                <!-- Checkbox Column -->

                <!-- Table with selection -->
                <!--    <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-checkbox">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>-->
                <ng-container matColumnDef="patientId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Patient ID</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.patientId}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.salutationType? item.salutationType.salutationName:''}}
                        {{item.firstName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Gender</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.gender==1?'Female':'Male'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="dateOfBirth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>DOB</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.dateOfBirth  | date:'dd-MM-yyyy'}}
                        <!-- {{item.dateOfBirth * 1000 | date:'yyyy-MM-dd'}}-->
                    </mat-cell>
                </ng-container>
                <!--
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Email</mat-header-cell>
                    <mat-cell *matCellDef="let item" class="email-cell">
                        <a href="user-management/users#" class="font-weight-bold">{{item.email}}</a>
                    </mat-cell>
                </ng-container>-->



                <ng-container matColumnDef="organisation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Organisation</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.organisation? item.organisation.nameOfOrganisation:''}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="center">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Center</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.center? item.center.nameOfCenter:''}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="study">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Study</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.study? item.study.studyName:''}}
                    </mat-cell>
                </ng-container>

                <!--   <ng-container matColumnDef="salutationType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Salutation Type</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.salutationType? item.salutationType.salutationName:''}}
                    </mat-cell>
                </ng-container>-->


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button *ngIf='isEditRights(item)' mat-icon-button color="primary"
                            (click)="edititem(item.id)">
                            <mat-icon>create </mat-icon>
                        </button>&nbsp;
                        <button mat-icon-button color="accent" (click)="viewitem(item.id)">
                            <mat-icon>remove_red_eye </mat-icon>
                        </button>&nbsp;
                        <!--  <button mat-icon-button color="warn" matTooltip="Delete item" type="button"
                            (click)="deleteitem(item)">
                            <mat-icon>delete</mat-icon>
                        </button>-->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <div class="mat-table__message" *ngIf="dataSource.data.length===0">No records found</div>
            <!-- Message for empty data  -->
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20,50,100]"
                [showFirstLastButtons]="true" [length]="totalSize" [pageIndex]="currentPage"
                (page)="handlePage($event)">
            </mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->
</kt-portlet>
