import { Organisation } from '../../auth';

export class Location {
    id: string;
    nameOfLocation: string;
    locationCode: string;
    expiresOn: number;
    organisation: Organisation;
    locationSequence: number;
    city: string;
    state: string;

    clear(): void {
        this.id = '';
        this.nameOfLocation = '';
        this.locationCode = '';
        this.expiresOn = 0;
        this.organisation = new Organisation();
        this.organisation.clear();
        this.locationSequence = 0;
        this.city = '';
        this.state = '';
    }

}