import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataCollectionCenter } from '../_models/data-collection-center.model';

@Injectable()
export class DataCollectionCenterService {


  constructor(private http: HttpClient) {
  }

  getAllCenters() {
    return this.http.get<DataCollectionCenter[]>(`${environment.host}${environment.apiUrls.dataCollectionCenter}`);
  }

  getCenterById(id: string) {
    return this.http.get<DataCollectionCenter>(`${environment.host}${environment.apiUrls.dataCollectionCenter}/${id}`);
  }

  createDataCenter(element: DataCollectionCenter) {
    return this.http.post(`${environment.host}${environment.apiUrls.dataCollectionCenter}`, element);
  }

  updateDataCenter(element: DataCollectionCenter) {
    return this.http.put(`${environment.host}${environment.apiUrls.dataCollectionCenter}`, element);
  }

  deletDataCenter(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.dataCollectionCenter}`, options);
  }

  activeDeactive(id) {
    return this.http.get<DataCollectionCenter>(`${environment.host}${environment.apiUrls.dataCollectionCenter}/activateDeActivate/${id}`);
  }
}
