import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Study } from '../../../../../../app/core/masters/_models/study.model';
import { Observable, Subscription, of } from 'rxjs';
import { StudyService } from 'src/app/core/masters/_services/study.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { currentUserOrganisation, Organisation } from 'src/app/core/auth';

@Component({
  selector: 'kt-study-edit',
  templateUrl: './study-edit.component.html',
  styleUrls: ['./study-edit.component.scss']
})
export class StudyEditComponent implements OnInit {

  studyForm: FormGroup;
  study: Study;
  organisation: Organisation;
  study$: Observable<Study>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  private subscriptions: Subscription[] = [];

  constructor(private _studyService: StudyService,
    public dialogRef: MatDialogRef<StudyEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private studyFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    this.study$ = of(this.data.item);

    const studySubscription = this.study$.subscribe(res => {
      if (!res) {
        return;
      }

      this.study = new Study();
      this.study = res;

      this.createForm();
    });
    this.subscriptions.push(studySubscription);
  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.studyForm = this.studyFB.group({
      studyName: [this.study.studyName, Validators.required],
      description: [this.study.description],
    });
  }

  prepareStudy(): Study {
    const controls = this.studyForm.controls;
    const _study = new Study();
    _study.clear();
    _study.id = this.study.id;
    _study.studyName = controls.studyName.value;
    _study.description = controls.description.value;
    _study.status = 'Approved';
    _study.organisation.id = this.organisation?.id;

    return _study;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.studyForm.controls;
    /** check form */
    if (this.studyForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedStudy = this.prepareStudy();
    if (editedStudy.id !== '') {
      this.updateStudy(editedStudy);
    } else {
      this.createStudy(editedStudy);
    }
  }


  updateStudy(_study: Study) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._studyService.updateStudy(_study)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _study,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createStudy(_study: Study) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._studyService.createStudy(_study)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _study,
          isEdit: false
        });
      }, (error) => {
        this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.study && this.study.id) {
      return `Edit study - '${this.study.studyName}'`;
    }
    return 'New Study';
  }

}
