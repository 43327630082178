import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MastersComponent } from './masters.component';
import { Routes, RouterModule } from '@angular/router';
import { LocationsListComponent } from './locations/locations-list/locations-list.component';
import { StudyListComponent } from './study/study-list/study-list.component';
import { CoreModule } from 'src/app/core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LocationsEditComponent } from './locations/locations-edit/locations-edit.component';
import { StudyEditComponent } from './study/study-edit/study-edit.component';
import { DataElementListComponent } from './data-element/data-element-list/data-element-list.component';
import { DataElementEditComponent } from './data-element/data-element-edit/data-element-edit.component';
import { QuestionMasterListComponent } from './question-master/question-master-list/question-master-list.component';
import { QuestionMasterEditComponent } from './question-master/question-master-edit/question-master-edit.component';
import { SalutationTypeListComponent } from './salutation-type/salutation-type-list/salutation-type-list.component';
import { SalutationTypeEditComponent } from './salutation-type/salutation-type-edit/salutation-type-edit.component';
import { DataCollectionCenterListComponent } from './data-collection-center/data-collection-center-list/data-collection-center-list.component';
import { DataCollectionCenterEditComponent } from './data-collection-center/data-collection-center-edit/data-collection-center-edit.component';
import { ProbandListComponent } from './proband/proband-list/proband-list.component';
import { ProbandEditComponent } from './proband/proband-edit/proband-edit.component';
import { ProbandViewComponent } from './proband/proband-view/proband-view.component';
import { QuestionnaireListComponent } from './questionnaire/questionnaire-list/questionnaire-list.component';
import { QuestionnaireEditComponent } from './questionnaire/questionnaire-edit/questionnaire-edit.component';
import { SectionListComponent } from './section/section-list/section-list.component';
import { SectionEditComponent } from './section/section-edit/section-edit.component';
import { EncounterListComponent } from './encounter/encounter-list/encounter-list.component';
import { EncounterEditComponent } from './encounter/encounter-edit/encounter-edit.component';
// import { EcrfComponent } from './ecrf/ecrf.component';
import { QuestionBeansComponent } from './ecrf/question-beans/question-beans.component';
import { MatSliderModule } from '@angular/material/slider';

const routes: Routes = [
  {
    path: '',
    component: MastersComponent,
    children: [
      {
        path: '',
        redirectTo: 'Patient',
        pathMatch: 'full'
      },
      {
        path: 'location',
        component: LocationsListComponent
      },
      {
        path: 'study',
        component: StudyListComponent
      },
      {
        path: 'encounter',
        component: EncounterListComponent
      },
      {
        path: 'Patient',
        component: ProbandListComponent
      },
      {
        path: 'Patient/add',
        component: ProbandEditComponent
      },
      {
        path: 'Patient/edit/:id',
        component: ProbandEditComponent
      },
      {
        path: 'Patient/view/:id',
        component: ProbandViewComponent
      },
      {
        path: 'data-element',
        component: DataElementListComponent
      },
      {
        path: 'question-master',
        component: QuestionMasterListComponent
      },
      {
        path: 'salutation-type',
        component: SalutationTypeListComponent
      },
      {
        path: 'data-collection-center',
        component: DataCollectionCenterListComponent
      },
      {
        path: 'questionnaire',
        component: QuestionnaireListComponent
      },
      {
        path: 'section',
        component: SectionListComponent
      },
      // {
      //   path: 'ecrf/:encounter/:questionnaire/:proband/:study/:center',
      //   component: EcrfComponent
      // }

    ]
  }
];



@NgModule({
  declarations: [MastersComponent,
    LocationsListComponent,
    StudyListComponent,
    LocationsEditComponent,
    StudyEditComponent,
    DataElementListComponent,
    DataElementEditComponent,
    QuestionMasterListComponent,
    QuestionMasterEditComponent,
    SalutationTypeListComponent,
    SalutationTypeEditComponent,
    DataCollectionCenterListComponent,
    DataCollectionCenterEditComponent,
    ProbandListComponent,
    ProbandEditComponent,
    ProbandViewComponent,
    QuestionnaireListComponent,
    QuestionnaireEditComponent,
    SectionListComponent,
    SectionEditComponent,
    EncounterListComponent,
    EncounterEditComponent,
    // EcrfComponent,
    QuestionBeansComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatSliderModule
  ],
  exports: [
    ProbandListComponent
  ],
  entryComponents: [LocationsEditComponent,
    StudyEditComponent,
    DataElementEditComponent,
    QuestionMasterEditComponent,
    SalutationTypeEditComponent,
    DataCollectionCenterEditComponent,
    QuestionnaireEditComponent,
    EncounterEditComponent,
    SectionEditComponent]
})
export class MastersModule { }
