export class DataElement {
    id: string;
    elementName: string;
    elementType: string;
    description: string;
    instructions: string;
    specialInstructions: string;

    clear(): void {
        this.id = '';
        this.elementName = '';
        this.elementType = '';
        this.description = '';
        this.instructions = '';
        this.specialInstructions = '';
    }
}