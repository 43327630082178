import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Questionnaire } from 'src/app/core/masters/_models/questionnaire.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { QuestionnaireService } from 'src/app/core/masters/_services/questionnaire.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from 'src/app/core/_base/layout';
import { QuestionnaireEditComponent } from '../questionnaire-edit/questionnaire-edit.component';

@Component({
  selector: 'kt-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss']
})
export class QuestionnaireListComponent implements OnInit {

  // Table fields
  dataSource = new MatTableDataSource<Questionnaire>();
  displayedColumns = ['select', 'questionnaireName', 'critieria', 'sortOrder', 'organisation', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Questionnaire>(true, []);
  questionnaireResult: Questionnaire[] = [];

  viewLoading = false;
  loadingAfterSubmit = false;
  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _questionnaireService: QuestionnaireService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Questionnaires');
    this.getAllQuestionnaire();
  }

  getAllQuestionnaire() {
    this.isLoading = true;
    this._questionnaireService.getAllQuestionnaire()
      .subscribe(res => {
        this.questionnaireResult = res;
        this.dataSource = new MatTableDataSource<Questionnaire>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.questionnaireResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.questionnaireResult.length) {
      this.selection.clear();
    } else {
      this.questionnaireResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.questionnaireName,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(item: Questionnaire) {
    const _saveMessage = `Questionnaire successfully has been saved.`;
    const _messageType = item.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(QuestionnaireEditComponent, { data: { item: item }, width: '40%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllQuestionnaire();
    });
  }


  add() {
    const newItem = new Questionnaire();
    newItem.clear(); // Set all defaults fields
    this.edititem(newItem);
  }

  deleteitem(_item: Questionnaire) {
    const _title = 'Questionnaire';
    const _description = 'Are you sure to permanently delete this questionnaire?';
    const _waitDesciption = 'Questionnaire is deleting...';
    const _deleteMessage = `Questionnaire has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._questionnaireService.deletQuestionnaire(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllQuestionnaire();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  fileUpload($event,organisationId,questionnaireName) {

    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._questionnaireService.uploadQuestionnaire($event.target.files[0],organisationId, questionnaireName)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;

        }
      });

    this.subscriptions.push(componentSubscriptions);
  }

}
