import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SubheaderService, LayoutConfigService } from '../.../../../../../../../app/core/_base/layout';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import {
  Organisation,
  User,
  AuthService
} from '../../../../../core/auth';

@Component({
  selector: 'kt-organisation-edit',
  templateUrl: './organisation-edit.component.html',
  styleUrls: ['./organisation-edit.component.scss']
})

export class OrganisationEditComponent implements OnInit {

  // Public properties
  organisation: Organisation;
  oldOrganisation: Organisation;
  organisationForm: FormGroup;
  hasFormErrors = false;
  isEdit: boolean = true;
  @ViewChild('wizard', { static: true }) el: ElementRef;

  // Private properties
  private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param layoutConfigService: LayoutConfigService
	 */
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private organisationFB: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private auth: AuthService,
    private _cdr: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.organisation = new Organisation();
    this.createForm();
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      if (id) {
        const selectorSubscription = this.auth.getOrganisationById(id).subscribe(res => {
          if (res) {
            this.isEdit = true;
            this.organisation = res;
            this.oldOrganisation = Object.assign({}, this.organisation);
            this.initOrganisation();
            this._cdr.detectChanges();
          }
        });
        this.subscriptions.push(selectorSubscription);
      } else {
        this.isEdit = false;
        this.organisation = new Organisation();
        this.organisation.clear();
        this.oldOrganisation = Object.assign({}, this.organisation);
        this.initOrganisation();
      }
    });

    this.subscriptions.push(routeSubscription);
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }


  /**
	 * Init organisation
	 */
  initOrganisation() {
    this.createForm();
    if (this.organisation.id === '') {
      this.subheaderService.setTitle('Create organisation');
      this.subheaderService.setBreadcrumbs([
        { title: 'User Management', page: `user-management` },
        { title: 'Organisations', page: `user-management/users` },
        { title: 'Create organisation', page: `user-management/users/add` }
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit organisation');
    this.subheaderService.setBreadcrumbs([
      { title: 'User Management', page: `user-management` },
      { title: 'Organisations', page: `user-management/users` },
      { title: 'Edit organisation', page: `user-management/users/edit`, queryParams: { id: this.organisation.id } }
    ]);
  }


  /**
	 * Create form
	 */
  createForm() {
    this.organisationForm = this.organisationFB.group({
      nameOfOrganisation: [this.organisation.nameOfOrganisation, Validators.required],
      address: [this.organisation.address, Validators.required],
      email: [this.organisation.email, [Validators.email, Validators.required]],
      pinCode: [this.organisation.pinCode],
      primaryContact: [this.organisation.primaryContact, Validators.required],
      primaryContactMobileNUmber: [this.organisation.primaryContactMobileNUmber, Validators.required],
      user: this.organisationFB.group({
        username: ['', Validators.required],
        fullName: ['', Validators.required],
        email: ['', [Validators.email, Validators.required]],
        phoneNumber: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required]
      }),
    });
  }


  /**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
  onSumbit(withBack: boolean) {
    this.hasFormErrors = false;
    const controls = this.organisationForm.controls;
    /** check form */
    if (this.organisationForm.invalid) {
      Object.keys(controls).forEach(controlName => {

        if (controlName === 'user') {
          if (!this.isEdit) {
            if (controls[controlName].invalid) {
              const userControls = controls[controlName]['controls'];
              Object.keys(userControls).forEach(controlName => {
                userControls[controlName].markAsTouched();
              });
              this.hasFormErrors = true;
            }
          }
        } else {
          controls[controlName].markAsTouched();
          controls[controlName].invalid === true ? this.hasFormErrors = true : null;
        }
      });

      if (this.hasFormErrors) {
        return
      }
    }

    const editedOrganisation = this.prepareOrganisation();

    if (editedOrganisation.id !== '') {
      this.updateOrganisation(editedOrganisation, withBack);
      return;
    }

    this.addOrganisation(editedOrganisation, withBack);
  }

  /**
     * Returns prepared data for save
     */
  prepareOrganisation(): Organisation {
    const controls = this.organisationForm.controls;
    const _organisation = new Organisation();
    _organisation.clear();
    _organisation.nameOfOrganisation = controls.nameOfOrganisation.value;
    _organisation.address = controls.address.value;
    _organisation.email = controls.email.value;
    _organisation.expiresOn = this.organisation.expiresOn;
    _organisation.pinCode = controls.pinCode.value;
    _organisation.primaryContact = controls.primaryContact.value;
    _organisation.primaryContactMobileNUmber = controls.primaryContactMobileNUmber.value;
    _organisation.id = this.organisation.id;
    _organisation.user = this.prepareUser();
    return _organisation;
  }

  /**
	 * Returns prepared data for save
	 */
  prepareUser(): User {

    const controls = this.organisationForm.controls['user']['controls'];
    const _user = new User();
    _user.clear();

    _user.username = controls.username.value;
    _user.email = controls.email.value;
    _user.fullName = controls.fullName.value;
    _user.phoneNumber = controls.phoneNumber.value;
    _user.firstName = controls.firstName.value;
    _user.lastName = controls.lastName.value;
    return _user;
  }

  /**
     * Add organisation
     *
     * @param _organisation: organisation
     * @param withBack: boolean
     */
  addOrganisation(_organisation: Organisation, withBack: boolean) {
    const addSubscription = this.auth.createOrganisation(_organisation).subscribe(newId => {
      if (newId) {//
        const message = `New organisation has been added successfully.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
        this.goBackWithId();
      }
    });
    this.subscriptions.push(addSubscription);
  }

  /**
	 * Update organisation
	 *
	 * @param _organisation: Organisation
	 * @param withBack: boolean
	 */
  updateOrganisation(_organisation: Organisation, withBack: boolean) {

    const message = `Organisation successfully has been updated.`;
    this.auth.updateOrganisation(_organisation).subscribe(res => {
      if (res) {
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
        this.goBackWithId();
      }
    });
  }

  /**
 * Redirect to list
 *
 */
  goBackWithId() {
    const url = `/user-management/organisations`;
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }


	/**
	 * Returns component title
	 */
  getComponentTitle() {
    let result = 'Create organisation';
    if (!this.organisation || !this.organisation.id) {
      return result;
    }

    result = `Edit organisation - ${this.organisation.nameOfOrganisation}`;
    return result;
  }

  /**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  /**
	 * Reset
	 */
  reset() {
    this.organisation = Object.assign({}, this.oldOrganisation);
    this.createForm();
    this.hasFormErrors = false;
    this.organisationForm.markAsPristine();
    this.organisationForm.markAsUntouched();
    this.organisationForm.updateValueAndValidity();
  }
}

