import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SalutationType } from '../../../../../../app/core/masters/_models/salutation-type.model';
import { Observable, Subscription, of } from 'rxjs';
import { SalutationTypeService } from '../../../../../../app/core/masters/_services/salutation-type.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';

@Component({
  selector: 'kt-salutation-type-edit',
  templateUrl: './salutation-type-edit.component.html',
  styleUrls: ['./salutation-type-edit.component.scss']
})
export class SalutationTypeEditComponent implements OnInit {
  salutationForm: FormGroup;
  salutationType: SalutationType;
  salutationType$: Observable<SalutationType>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  private subscriptions: Subscription[] = [];

  constructor(private _salutationTypeService: SalutationTypeService,
    public dialogRef: MatDialogRef<SalutationTypeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
  ) { }

  ngOnInit(): void {

    this.salutationType$ = of(this.data.item);

    const elementSubscription = this.salutationType$.subscribe(res => {
      if (!res) {
        return;
      }

      this.salutationType = new SalutationType();
      this.salutationType = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(elementSubscription);
  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.salutationForm = this.FB.group({
      salutationName: [this.salutationType.salutationName, Validators.required]
    });
  }

  prepareData(): SalutationType {
    const controls = this.salutationForm.controls;
    const _data = new SalutationType();
    _data.clear();
    _data.id = this.salutationType.id;
    _data.salutationName = controls.salutationName.value;
    _data.version = this.salutationType.version;
    return _data;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.salutationForm.controls;
    /** check form */
    if (this.salutationForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedelement = this.prepareData();
    if (editedelement.id !== '') {
      this.updateSalutationType(editedelement);
    } else {
      this.createSalutationType(editedelement);
    }
  }


  updateSalutationType(_dataElement: SalutationType) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._salutationTypeService.updateSalutationType(_dataElement)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _dataElement,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createSalutationType(_dataElement: SalutationType) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._salutationTypeService.createSalutationType(_dataElement)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _dataElement,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.salutationType && this.salutationType.id) {
      return `Edit salutation type - '${this.salutationType.salutationName}'`;
    }
    return 'New salutation type';
  }


}
