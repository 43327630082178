import { Organisation } from '../../auth';
import { Location } from './location.model';

export class DataCollectionCenter {
    id: string;
    centerSequence: number;
    nameOfCenter: string;
    centerCode: string;
    centerAddress: string;
    primaryContactNumber: string;
    primaryMobileNumber: string;
    emailAddress: string;
    organisation: Organisation;
    location: Location;
    isActive = false;

    clear(): void {
        this.id = '';
        this.centerSequence = 0;
        this.nameOfCenter = '';
        this.centerCode = '';
        this.centerAddress = '';
        this.primaryContactNumber = '';
        this.primaryMobileNumber = '';
        this.emailAddress = '';
        this.organisation = new Organisation();
        this.organisation.clear();
        this.location = new Location();
        this.location.clear();

    }

}
