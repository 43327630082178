import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataElement } from '../../../../../../app/core/masters/_models/data-element.model';
import { Observable, Subscription, of } from 'rxjs';
import { DataElementsService } from 'src/app/core/masters/_services/data-elements.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';

@Component({
  selector: 'kt-data-element-edit',
  templateUrl: './data-element-edit.component.html',
  styleUrls: ['./data-element-edit.component.scss']
})
export class DataElementEditComponent implements OnInit {
  dataElementForm: FormGroup;
  dataElement: DataElement;
  dataElement$: Observable<DataElement>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;

  private subscriptions: Subscription[] = [];

  constructor(private _dataElementService: DataElementsService,
    public dialogRef: MatDialogRef<DataElementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataElementFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
  ) { }

  ngOnInit(): void {

    this.dataElement$ = of(this.data.item);

    const dataElementSubscription = this.dataElement$.subscribe(res => {
      if (!res) {
        return;
      }

      this.dataElement = new DataElement();
      this.dataElement = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(dataElementSubscription);
  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.dataElementForm = this.dataElementFB.group({
      elementName: [this.dataElement.elementName, Validators.required],
      elementType: [this.dataElement.elementType],
      description: [this.dataElement.description, Validators.required],
      instructions: [this.dataElement.instructions],
      specialInstructions: [this.dataElement.specialInstructions]
    });
  }

  prepareDataElement(): DataElement {
    const controls = this.dataElementForm.controls;
    const _dataElement = new DataElement();
    _dataElement.clear();
    _dataElement.id = this.dataElement.id;
    _dataElement.elementName = controls.elementName.value;
    _dataElement.elementType = controls.elementType.value;
    _dataElement.description = controls.description.value;
    _dataElement.instructions = controls.instructions.value;
    _dataElement.specialInstructions = controls.specialInstructions.value;
    return _dataElement;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.dataElementForm.controls;
    /** check form */
    if (this.dataElementForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedDataElement = this.prepareDataElement();
    if (editedDataElement.id !== '') {
      this.updateDataElement(editedDataElement);
    } else {
      this.createDataElement(editedDataElement);
    }
  }


  updateDataElement(_dataElement: DataElement) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._dataElementService.updateDataElement(_dataElement)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _dataElement,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createDataElement(_dataElement: DataElement) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._dataElementService.createDataElement(_dataElement)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _dataElement,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.dataElement && this.dataElement.id) {
      return `Edit data element - '${this.dataElement.elementName}'`;
    }
    return 'New data element';
  }

}
