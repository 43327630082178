import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-masters',
  templateUrl: './masters.component.html',
})
export class MastersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
