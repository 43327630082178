<kt-portlet>
	<kt-portlet-header [title]="getComponentTitle()" [class]="'card-head-lg'" [viewLoading$]="loading$">
		<ng-container ktPortletTools>
			<a [routerLink]="['/user-management/users']" class="btn btn-secondary mr-2" mat-raised-button
				matTooltip="Back to the users list">
				<i class="la la-arrow-left"></i>
				<span class="hidden-mobile">Back</span>
			</a>
			<a href="javascript:;" class="btn btn-secondary mr-2" (click)="reset()" [disabled]="selectedTab !== 0"
				mat-raised-button matTooltip="Reset changes">
				<i class="la la-cog"></i>
				<span class="hidden-mobile">Reset</span>
			</a>
			<a href="javascript:;" class="btn btn-secondary mr-2" (click)="passwordReset()" *ngIf="user && user.id"
				mat-raised-button matTooltip="Reset changes">
				<i class="la la-cog"></i>
				<span class="hidden-mobile">Password Reset</span>
			</a>
			<a href="javascript:;" class="btn btn-primary mr-2" color="primary" (click)="onSumbit(true)"
				mat-raised-button matTooltip="Save & Continue">
				<span class="hidden-mobile">Save</span>
			</a>
			<button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item color="primary" (click)="onSumbit(true)">Save & Exit</button>
				<button mat-menu-item color="primary">Save & Duplicate</button>
				<button mat-menu-item color="primary" (click)="onSumbit(false)">Save & Continue</button>
			</mat-menu>
		</ng-container>
	</kt-portlet-header>

	<kt-portlet-body>
		<mat-tab-group [(selectedIndex)]="selectedTab">
			<mat-tab>
				<ng-template mat-tab-label>
					<i class="mat-tab-label-icon fa fa-user"></i>
					Basic info
				</ng-template>
				<ng-template matTabContent>
					<!--begin::Form-->
					<div *ngIf="user">
						<form [formGroup]="userForm" class="form form-group-seperator-dashed">

							<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
								(close)="onAlertClose($event)">
								Oh snap! Change a few things up and try submitting again.
							</kt-alert>
							<div class="form-section form-section-first">
								<div class="form-group row">
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Username" formControlName="username" />
											<mat-error>Username is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Username</strong>
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input type="password" matInput placeholder="Password" formControlName="password" />
											<mat-error>Password is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>password</strong>
											</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input type="email" matInput placeholder="Enter Email"
												formControlName="email" />
											<mat-error>Email is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Email</strong>
											</mat-hint>
										</mat-form-field>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter First Name"
												formControlName="firstName" />
											<mat-error>First Name is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>First Name</strong>
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Last Name" formControlName="lastName" />
											<mat-error>Last Name is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Last Name</strong>
											</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Phone Number"
												formControlName="phoneNumber" />
											<mat-error>Phone Number is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Phone Number</strong>
											</mat-hint>
										</mat-form-field>
									</div>
								</div>

                <div class="form-group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <mat-select  placeholder="Select Role" formControlName="roleName" multiple>
                        <mat-option *ngFor="let item of role$ | async" value="{{ item.roleName }}">
                          {{item.roleName}}</mat-option>
                      </mat-select>
                      <mat-error>Role
                        <strong> is Required </strong>
                      </mat-error>
                      <mat-hint align="start">
                        <strong>Select Role</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <mat-select placeholder="Select location" formControlName="locationId">
                        <mat-option *ngFor="let item of location$ | async" value="{{ item.id }}">
                          {{item.nameOfLocation}}</mat-option>
                      </mat-select>
                      <mat-error>Location
                        <strong> is Required </strong>
                      </mat-error>
                      <mat-hint align="start">
                        <strong>Select Location</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <mat-select placeholder="Select Center" formControlName="centerId">
                        <mat-option *ngFor="let item of center$ | async" value="{{ item.id }}">
                          {{item.nameOfCenter}}</mat-option>
                      </mat-select>
                      <mat-error>Center
                        <strong> is Required </strong>
                      </mat-error>
                      <mat-hint align="start">
                        <strong>Select Center</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>


                </div>
							</div>
						</form>
					</div>
					<!--end::Form-->
				</ng-template>
			</mat-tab>

		</mat-tab-group>
	</kt-portlet-body>
</kt-portlet>
