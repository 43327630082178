<kt-portlet>
    <kt-portlet-header [title]="getComponentTitle()" [class]="'card-head-lg'">
        <ng-container ktPortletTools>
            <a [routerLink]="['/masters/Patient']" class="btn btn-secondary mr-2" mat-raised-button
                matTooltip="Back to the users list">
                <i class="la la-arrow-left"></i>
                <span class="hidden-mobile">Back</span>
            </a>
            <a href="javascript:;" class="btn btn-secondary mr-2" (click)="reset()" mat-raised-button
                matTooltip="Reset changes">
                <i class="la la-cog"></i>
                <span class="hidden-mobile">Reset</span>
            </a>
            <a href="javascript:;" class="btn btn-primary mr-2" color="primary" (click)="onSumbit(true)"
                mat-raised-button matTooltip="Save & Continue">
                <span class="hidden-mobile">Save</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <!--begin::Form-->
        <div>
            <form [formGroup]="probandForm" class="form" autocomplete="off">

                <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
                    (close)="onAlertClose($event)">
                    Oh snap! Change a few things up and try submitting again.
                </kt-alert>

                <div class="form-section form-section-first pb-5">
                    <h3>Basic Info</h3>

                    <div class="form-group row">
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select Salutation Type" formControlName="salutationTypeId">
                                    <mat-option *ngFor="let item of salutationType$ | async" value="{{ item.id }}">
                                        {{item.salutationName}}</mat-option>
                                </mat-select>
                                <mat-error>Salutation Type
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Salutation Type</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter First Name" formControlName="firstName" />
                                <mat-error>First Name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>First Name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Last Name" formControlName="lastName" />
                                <mat-error>Last Name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Last Name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <!-- <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input type="number" matInput placeholder="Enter Age" formControlName="age" />
                                <mat-error>Age is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Age</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>-->
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Gender" formControlName="gender">
                                    <mat-option value="1">Female</mat-option>
                                    <mat-option value="2">Male</mat-option>
                                </mat-select>
                                <mat-error>Gender is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Gender</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput [matDatepicker]="picker" placeholder="Enter Date Of Birth"
                                    formControlName="dateOfBirth" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error>Date Of Birth is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Date Of Birth</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Marital Status" formControlName="maritalStatus" />
                                <mat-error>Marital Status is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Marital Status</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="form-group row">

                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Email" formControlName="email" />
                                <mat-error>Email is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Email</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Mobile Number" formControlName="mobileNumber" />
                                <mat-error>Mobile Number is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Mobile Number</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">

                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Guardian Name" formControlName="guardianName" />
                                <mat-error>Guardian Name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Guardian Name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Guardian Phone Number"
                                    formControlName="guardianPh" />
                                <mat-error>Guardian Phone Number is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Guardian Phone Number</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>


                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Address" formControlName="address1" />
                                <mat-error>Address is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Address</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>



                </div>

                <div class="form ">
                    <h3>Additional Info</h3>
                    <div class="form-section form-section-first">

                        <div class="row form-group">

                            <div class="col-lg-4 kt-margin-bottom-20-mobile"
                                *ngIf="!(isCenteruserRole || isSuperAdminRole)">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Select location" formControlName="locationId">
                                        <mat-option *ngFor="let item of location$ | async" value="{{ item.id }}">
                                            {{item.nameOfLocation}}</mat-option>
                                    </mat-select>
                                    <mat-error>Location
                                        <strong> is Required </strong>
                                    </mat-error>
                                    <mat-hint align="start">
                                        <strong>Select Location</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="!isCenteruserRole">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Select Center" formControlName="centerId">
                                        <mat-option *ngFor="let item of center$ | async" value="{{ item.id }}">
                                            {{item.nameOfCenter}}</mat-option>
                                    </mat-select>
                                    <mat-error>Center
                                        <strong> is Required </strong>
                                    </mat-error>
                                    <mat-hint align="start">
                                        <strong>Select Center</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <input placeholder="Select Center" formControlName="centerId" type="hidden"
                                *ngIf="isCenteruserRole" value="{{loggedUser?.dataCollectionCenter?.id}}" />
                            <input placeholder="Select Location" formControlName="locationId" type="hidden"
                                *ngIf="(isCenteruserRole || isSuperAdminRole)"
                                value="{{loggedUser?.dataCollectionCenter?.location?.id}}" />

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Select Study" formControlName="studyId">
                                        <mat-option *ngFor="let item of study$ | async" value="{{ item.id }}">
                                            {{item.studyName}}</mat-option>
                                    </mat-select>
                                    <mat-error>Study
                                        <strong> is Required </strong>
                                    </mat-error>
                                    <mat-hint align="start">
                                        <strong>Select Study</strong>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                        </div>

                    </div>

                </div>

            </form>
        </div>

    </kt-portlet-body>
</kt-portlet>