import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { PasswordValidatorsService } from 'src/app/core/_base/layout/services/password-validators.service';
function mustMatch(c: AbstractControl, password): { invalid: boolean } {
  if (c.value !== password) {
    return { invalid: true };
  }
  return null
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  userId: string;
  isSucess = false;
  error: boolean = false;
  currentUser: any;
  isLoading = false;
  submitted = false;
  form: FormGroup;
  token: string;
  isTokenValid: boolean;
  tokenMessage: string;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private passwordValidatorsService: PasswordValidatorsService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
  ) {
    /*    this.currentUser = this.tokenService.getUser();
    this.token = this.tokenService.getToken();*/
    this.form = this.fb.group(
      {
        password: [
          null,
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(12),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[\\S]*'),
          ],
        ],
        confirm_password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(12),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[\\S]*'),
          ],
        ],
      }, {
      validators: this.passwordValidatorsService.confimrPasswordValidator('password', 'confirm_password'),
    }
    );
  }


  // checkResetPasswordTokenIsValid() {
  //   this.homeService.checkResetPasswordTokenIsValid(this.token).subscribe((resp: any) => {
  //     if (resp !== undefined && resp != null) {
  //       this.tokenMessage = resp.message;
  //       this.isTokenValid = resp.statusCode === 200;
  //       this.router.navigate(['/reset-password/' + this.token]);
  //     }
  //   });
  // }
  showHideConfirmPwd() {
    console.log(this.form.get('confirm_password'));
    console.log(this.form.get('confirm_password').hasError('passwordMismatch'));
  }

  // submit() {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   } else {
  //     console.log(this.f.password.value);
  //     this.homeService
  //       .resetPassword({ newPassword: this.f.password.value, token: this.token }, this.token)
  //       .subscribe((resp: any) => {
  //         if (resp !== undefined && resp != null) {
  //           if (resp.statusCode === 200) {
  //             // this.toasterService.pop('success', 'Reset Password successful', resp.message);
  //             this.f.password.reset();
  //             this.f.confirm_password.reset();
  //             this.router.navigate(['']);
  //           }
  //         }
  //       });
  //   }
  // }

  ngOnInit() {
    this.error = false;
    this.isLoading = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('id');
      this.userId = params.get('userId');
      // this.checkResetPasswordTokenIsValid();
    });
    console.log(this.isSucess)
  }
  /*
  showHidePwd() {
    var passwordIdType = $('#pwd').attr('type');
    if (passwordIdType === 'password') {
      $('#pwd').attr('type', 'text');
      $('#pwdhider').addClass('hider');
    } else {
      $('#pwd').attr('type', 'password');
      $('#pwdhider').removeClass('hider');
    }
  }

  showHideConfirmPwd() {
    var passwordIdType = $('#cnfrmpwd').attr('type');
    if (passwordIdType === 'password') {
      $('#cnfrmpwd').attr('type', 'text');
      $('#cnfrmpwdpwdhider').addClass('hider');
    } else {
      $('#cnfrmpwd').attr('type', 'password');
      $('#cnfrmpwdpwdhider').removeClass('hider');
    }
  }

   */
  submit() {
    const bodyData = {
      oldPassword: this.form.get('password').value,
      newPassword: this.form.get('confirm_password').value,
      token: this.token,
      userId: this.userId
    }
    if (this.userId) {
      this.updatePassword(bodyData)
    } else {
      this.resetPassword(bodyData);
    }
  }

  async updatePassword(bodyData) {
    try {
      const data = await this.authService.updatePassword(bodyData);
      this.router.navigate(['user-management/users']);
      this.layoutUtilsService.showActionNotification('Password generated Sucessfully', MessageType.Update, 5000, true, true);
    } catch (e) {
      this.error = true;
      this.layoutUtilsService.showActionNotification('password generation Failed', MessageType.Update, 5000, true, true);

    } finally {
      this.cdr.detectChanges();
    }

  }
  async resetPassword(bodyData) {
    try {
      const data = await this.authService.resetPassword(this.token, bodyData);
      this.router.navigateByUrl('/auth/login');
      this.layoutUtilsService.showActionNotification('Password generated Sucessfully', MessageType.Update, 5000, true, true);
    } catch (e) {
      this.error = true;
      this.layoutUtilsService.showActionNotification('password generation Failed', MessageType.Update, 5000, true, true);

    } finally {
      this.cdr.detectChanges();
    }
  }

}
