import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SalutationType } from '../../../../../../app/core/masters/_models/salutation-type.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { SalutationTypeService } from 'src/app/core/masters/_services/salutation-type.service';
import { MatDialog } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { SalutationTypeEditComponent } from '../salutation-type-edit/salutation-type-edit.component';

@Component({
  selector: 'kt-salutation-type-list',
  templateUrl: './salutation-type-list.component.html',
  styleUrls: ['./salutation-type-list.component.scss']
})
export class SalutationTypeListComponent implements OnInit {

  // Table fields
  dataSource = new MatTableDataSource<SalutationType>();
  displayedColumns = ['select', 'salutationName', 'version', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<SalutationType>(true, []);
  salutationTypeResult: SalutationType[] = [];

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _salutationTypeService: SalutationTypeService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Salutation Types');
    this.getAllSalutationTypes();
  }

  getAllSalutationTypes() {
    this.isLoading = true;
    this._salutationTypeService.getAllsalutationTypes()
      .subscribe(res => {
        this.salutationTypeResult = res;
        this.dataSource = new MatTableDataSource<SalutationType>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.salutationTypeResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.salutationTypeResult.length) {
      this.selection.clear();
    } else {
      this.salutationTypeResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.salutationName,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(item: SalutationType) {
    const _saveMessage = `Salutation type successfully has been saved.`;
    const _messageType = item.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(SalutationTypeEditComponent, { data: { item: item }, width: '40%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllSalutationTypes();
    });
  }


  add() {
    const newItem = new SalutationType();
    newItem.clear(); // Set all defaults fields
    this.edititem(newItem);
  }

  deleteitem(_item: SalutationType) {
    const _title = 'Salutation Type';
    const _description = 'Are you sure to permanently delete this salutation type?';
    const _waitDesciption = 'Salutation type is deleting...';
    const _deleteMessage = `Salutation type has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._salutationTypeService.deletSalutationType(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllSalutationTypes();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
