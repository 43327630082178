import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataCollectionCenter } from 'src/app/core/masters/_models/data-collection-center.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { DataCollectionCenterService } from 'src/app/core/masters/_services/data-collection-center.service';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { DataCollectionCenterEditComponent } from '../data-collection-center-edit/data-collection-center-edit.component';

@Component({
  selector: 'kt-data-collection-center-list',
  templateUrl: './data-collection-center-list.component.html',
  styleUrls: ['./data-collection-center-list.component.scss']
})
export class DataCollectionCenterListComponent implements OnInit {


  // Table fields
  dataSource = new MatTableDataSource<DataCollectionCenter>();
  displayedColumns = ['select', 'nameOfCenter', 'emailAddress', 'centerCode', 'location', 'organisation','isActive', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<DataCollectionCenter>(true, []);
  centerResult: DataCollectionCenter[] = [];

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _centerService: DataCollectionCenterService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Data Collection Centers');
    this.getAllCenters();
  }

  getAllCenters() {
    this.isLoading = true;
    this._centerService.getAllCenters()
      .subscribe(res => {
        this.centerResult = res;
        this.dataSource = new MatTableDataSource<DataCollectionCenter>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.centerResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.centerResult.length) {
      this.selection.clear();
    } else {
      this.centerResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.nameOfCenter,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(item: DataCollectionCenter) {
    const _saveMessage = `Center successfully has been saved.`;
    const _messageType = item.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(DataCollectionCenterEditComponent, { data: { item: item }, width: '60%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllCenters();
    });
  }


  add() {
    const newItem = new DataCollectionCenter();
    newItem.clear(); // Set all defaults fields
    this.edititem(newItem);
  }

  deleteitem(_item: DataCollectionCenter) {
    const _title = 'Data Collection Center';
    const _description = 'Are you sure to permanently delete this center?';
    const _waitDesciption = 'Center is deleting...';
    const _deleteMessage = `Center has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._centerService.deletDataCenter(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllCenters();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deactivate(item) {
    this._centerService.activeDeactive(item.id)
      .subscribe(res => {
        this.layoutUtilsService.showActionNotification("De-Activated", MessageType.Delete);

        this._centerService.getAllCenters();
        window.location.reload();
        this.isLoading = false;
      });
  }
}
