// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import {
  User,
  UserUpdated,
  selectHasUsersInStore,
  selectUserById,
  UserOnServerCreated,
  selectLastCreatedUserId,
  selectUsersActionLoading,
  currentUserOrganisation, Organisation, currentUser, Role
} from '../../../../../core/auth';
import {Location} from "../../../../../core/masters/_models/location.model";
import {DataCollectionCenter} from "../../../../../core/masters/_models/data-collection-center.model";
import { LocationService } from 'src/app/core/masters/_services/location.service';
import { DataCollectionCenterService } from 'src/app/core/masters/_services/data-collection-center.service';
import { AuthService } from 'src/app/core/auth/_services/auth.service';

import {SalutationTypeService} from "../../../../../core/masters/_services/salutation-type.service";

@Component({
	selector: 'kt-user-edit',
	templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit, OnDestroy {
	// Public properties
	user: User;
	userId$: Observable<number>;
	oldUser: User;
	organisation: Organisation;
	selectedTab = 0;
	loading$: Observable<boolean>;
	userForm: FormGroup;
	hasFormErrors = false;
  loggedUser: User;
  location$: Observable<Location[]>;
  center$: Observable<DataCollectionCenter[]>;
  role$: Observable<Role[]>;
  isCenteruserRole: boolean;
  isSuperAdminRole: boolean;

	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private userFB: FormBuilder,
		private subheaderService: SubheaderService,
  	private layoutUtilsService: LayoutUtilsService,
    private _locationService: LocationService,
    private _centerService: DataCollectionCenterService,
		private _roleService: AuthService,
		private store: Store<AppState>,
		private layoutConfigService: LayoutConfigService) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));
		this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    this.store.pipe(select(currentUser)).subscribe(res => this.loggedUser = res);
    this.location$ = this._locationService.getAllLocations();
    this.center$ = this._centerService.getAllCenters();
    this.role$ = this._roleService.getAllRoles();
		const routeSubscription = this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id) {
				const selectorSubscription = this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;

						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
				this.subscriptions.push(selectorSubscription);

			} else {
				this.user = new User();
				this.user.clear();

				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (this.user.id === '') {
			this.subheaderService.setTitle('Create user');
			this.subheaderService.setBreadcrumbs([
				{ title: 'User Management', page: `user-management` },
				{ title: 'Users', page: `user-management/users` },
				{ title: 'Create user', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit user');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users', page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.userForm = this.userFB.group({
			username: [this.user.username, Validators.required],
      fullName: [this.user.fullName],
			password: [this.user.password],
			email: [this.user.email, [Validators.email, Validators.required]],
			phoneNumber: [this.user.phoneNumber],
			firstName: [this.user.firstName, Validators.required],
			lastName: [this.user.lastName, Validators.required],
      locationId: [this.user?.location?.id,/* Validators.required*/],
      centerId: [this.user?.dataCollectionCenter?.id, /*Validators.required*/],
      roleName: [this.user?.authorities.map(m=>m['authority']), /*Validators.required*/]
		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean, id = '') {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.userForm.markAsPristine();
		this.userForm.markAsUntouched();
		this.userForm.updateValueAndValidity();
	}

	passwordReset() {
		this.router.navigateByUrl(`/user-management/users/reset-password/${this.user.id}`, { relativeTo: this.activatedRoute });
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean) {
		this.hasFormErrors = false;
		const controls = this.userForm.controls;
		console.log(controls)
		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedUser = this.prepareUser();
		//console.log(editedUser)

		if (editedUser.id !== '') {
			this.updateUser(editedUser, withBack);
			return;
		}

		this.addUser(editedUser, withBack);
	}

	/**
	 * Returns prepared data for save
	 */
	prepareUser(): User {
		const controls = this.userForm.controls;
		const _user = new User();
		_user.clear();
      console.log(controls)
  //  _user.dataCollectionCenter = this.loggedUser?.dataCollectionCenter;
		//_user.pic = this.user.pic;
		_user.id = this.user.id;
		_user.username = controls.username.value;
    _user.password = controls.password.value;
		_user.email = controls.email.value;
		_user.fullName = controls.fullName.value;
		_user.phoneNumber = controls.phoneNumber.value;
		_user.firstName = controls.firstName.value;
		_user.lastName = controls.lastName.value;
		// _user.organisation.id = this.user.organisation.id;
		_user.organisation.id = this.organisation?.id;
    _user.organisation.id = this.organisation?.id;
		_user.enabled = this.user.enabled;
		_user.accountNonExpired = this.user.accountNonExpired;
		_user.accountNonLocked = this.user.accountNonLocked;
		_user.credentialsNonExpired = this.user.credentialsNonExpired;
		if (controls.locationId.value!='') {
      _user.location.id = controls.locationId.value;
    } else {
      delete _user['location'];
    }
    if (controls.centerId.value!='') {
      _user.dataCollectionCenter.id = controls.centerId.value;
    } else {
      _user['dataCollectionCenter']=null;
    }
    _user.roles = controls.roleName.value;
		return _user;
	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addUser(_user: User, withBack: boolean) {
		this.store.dispatch(new UserOnServerCreated({ user: _user }));
		const addSubscription = this.store.pipe(select(selectLastCreatedUserId)).subscribe(newId => {
			const message = `New user successfully has been added.`;
			if (newId && newId !== '') {
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
				if (withBack) {
					this.goBackWithId();
				} else {
					this.refreshUser(true, newId);
				}
			}
		});
		this.subscriptions.push(addSubscription);
	}

	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updateUser(_user: User, withBack: boolean) {
		// Update User
		// tslint:disable-next-line:prefer-const

		const updatedUser: Update<User> = {
			id: _user.id,
			changes: _user
		};
		this.store.dispatch(new UserUpdated({ partialUser: updatedUser, user: _user }));
		const message = `User successfully has been saved.`;

		this.store.pipe(select(selectUsersActionLoading))
			.subscribe(res => {
				if (!res) {
					this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
					if (withBack) {
						this.goBackWithId();
					} else {
						this.refreshUser(false);
					}
				}
			});

	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Create user';
		if (!this.user || !this.user.id) {
			return result;
		}

		result = `Edit user - ${this.user.username}`;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
