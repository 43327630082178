import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class MappingsService {


  constructor(private http: HttpClient) {
  }

  saveMapping(mapping) {
    return this.http.post<any>(`${environment.host}${environment.apiUrls.dataMapping}`, mapping);
  }

}
