<kt-portlet>

    <kt-portlet-header [title]="'Questionnaire list'" [class]="'card-head-lg'">

        <ng-container ktPortletTools>
            <button (click)="add()" mat-raised-button matTooltip="Create new questionnaire"
                matTooltipClass="example-tooltip-red" color="primary" type="button">
                <span>New Questionnaire</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form kt-margin-b-30">
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search" placeholder="Search"
                                (keyup)="applyFilter($event.target.value)">
                            <mat-hint align="start">
                                <strong>Search</strong> in all fields
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->


            <div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
                [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="form-group form-group-inline">
                        <div class="form-label form-label-no-wrap">
                            <label class="font-bold font-danger">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
                                {{ selection.selected.length }}
                            </label>
                        </div>
                        <div>
                            <button (click)="fetchitems()" mat-raised-button matTooltip="Fetch selected locations"
                                class="mat-button-mt-4">
                                <mat-icon>clear_all</mat-icon>
                                Fetch Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->


        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="id" matSortDirection="asc" matSortDisableClear>
                <!-- Checkbox Column -->

                <!-- Table with selection -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-checkbox">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="questionnaireName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.questionnaireName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="critieria">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Critieria</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.critieria}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sortOrder">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Sort Order</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.sortOrder}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="organisation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Organisation</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.organisation?item.organisation.nameOfOrganisation:''}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button mat-icon-button color="primary" (click)="edititem(item)">
                            <mat-icon>create </mat-icon>
                        </button>&nbsp;


                      <div class=" image-input-empty " id="kt_image_5">
                        <div class="image-input-wrapper"></div>

                        <label class="btn btn-sm btn-primary mr-3 btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                               data-action="change" data-toggle="tooltip" title="" data-original-title="Upload File">
                                 <span class="svg-icon menu-icon">
                                                  <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Files/Upload.svg-->
                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                       xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                       height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                      <rect x="0" y="0" width="24" height="24"></rect>
                                                      <path
                                                        d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                      <rect fill="#000000" opacity="0.3" x="11" y="2" width="2"
                                                            height="14" rx="1"></rect>
                                                      <path
                                                        d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                    </g>
                                                  </svg>
                                   <!--end::Svg Icon-->
                                 </span>
                          <input type="file" name="uploadquestionnaire" (change)="fileUpload($event,item.organisation?.id,item.questionnaireName)"/>
                          <input type="hidden" name="profile_avatar_remove"/>
                        </label>


                      </div>


                      <!-- <button mat-icon-button color="warn" matTooltip="Delete item" type="button"
                           (click)="deleteitem(item)">`
                           <mat-icon>delete</mat-icon>
                       </button>-->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <div class="mat-table__message" *ngIf="dataSource.data.length===0">No records found</div>
            <!-- Message for empty data  -->
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->
</kt-portlet>
