import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { env } from 'process';

@Injectable()
export class QuestionMappingService {


  constructor(private http: HttpClient) {
  }

  getQuestionMappingByQuestionnaireId(id: string) {
    return this.http.get<any[]>(`${environment.host}${environment.apiUrls.questionMapping}${environment.apiUrls.getQuestionnarie}/${id}`);
  }

  getProbandStudyQuestionnaireResponse(encounterId: string, questionnaireId: string) {
    return this.http.get<any[]>(`${environment.host}${environment.apiUrls.encounter}${environment.apiUrls.getProbandStudyQuestionnaireResponse}/${encounterId}/${questionnaireId}`);
  }

  probandStudyQuestionnaireResponseSave(questionnaire, probandQuestionnarieResponseId) {
    if (!probandQuestionnarieResponseId) {
      return this.http.post<any>(`${environment.host}${environment.apiUrls.probandStudyQuestionnaireResponse}`, questionnaire);
    } else {
      return this.http.put<any>(`${environment.host}${environment.apiUrls.probandStudyQuestionnaireResponse}`, questionnaire);
    }
  }

  probandStudyQuestionnaireResponseFinish(questionnaire,status) {
    return this.http.post<any>(`${environment.host}${environment.apiUrls.probandStudyQuestionnaireResponse}${environment.apiUrls.questionnaireFinish}${status}`, questionnaire);
  }
}
