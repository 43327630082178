import { Component, OnInit, ViewChild } from '@angular/core';
import { Proband } from 'src/app/core/masters/_models/proband.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from 'src/app/core/_base/layout';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { ProbandService } from 'src/app/core/masters/_services/proband.service';
import { Observable, Subscription } from 'rxjs';
import { Encounter } from 'src/app/core/masters/_models/encounter.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { QuestionnaireService } from 'src/app/core/masters/_services/questionnaire.service';

@Component({
  selector: 'kt-proband-view',
  templateUrl: './proband-view.component.html',
  styleUrls: ['./proband-view.component.scss']
})
export class ProbandViewComponent implements OnInit {
  proband: Proband;
  private subscriptions: Subscription[] = [];

  // Table fields
  dataSource = new MatTableDataSource<Encounter>();
  displayedColumns = ['questionnaire', 'study', 'questionnaireStatus','UpdatedBy', 'UpdatedDate','actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Encounter>(true, []);
  isLoading: boolean = true;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private _probandService: ProbandService,
    public dialog: MatDialog,
    private _questionnaireService: QuestionnaireService) { }

  ngOnInit(): void {
    this.proband = new Proband();
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this._probandService.getProbandById(id).subscribe(res => {
          this.proband = res;
          //sort the questionaire using sortOrder
          this.proband.encounter.sort((a, b) => {
            return a.questionnaire.sortOrder - b.questionnaire.sortOrder;
          });
          this.dataSource = new MatTableDataSource<Encounter>(res.encounter);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;

          this.initProband();
        },
          (error) => {
            this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Update, 5000, true, true);
          });

      } else {
        this.initProband();

      }
    });

    this.subscriptions.push(routeSubscription);

  }


  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }


  initProband() {
    this.subheaderService.setTitle('View Patient');
    this.subheaderService.setBreadcrumbs([
      { title: 'Master', page: `masters` },
      { title: 'Patients', page: `masters/Patient` },
      { title: 'View Patient', page: `masters/Patient/view`, queryParams: { id: this.proband.id } }
    ]);
  }


  /**
 * Redirect to list
 *
 */
  goBackWithId() {
    const url = `/masters/Patient`;
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }


  getComponentTitle() {
    let result = 'Create Patient';
    if (!this.proband || !this.proband.id) {
      return result;
    }

    result = `View Patient - ${this.proband.firstName}`;
    return result;
  }

  getSalutation() {
    return this.proband.salutationType ? this.proband.salutationType.salutationName : null;
  }

  getLocation() {
    return this.proband.location ? this.proband.location.nameOfLocation : null;
  }

  getCenter() {
    return this.proband.center ? this.proband.center.nameOfCenter : null;
  }

  getStudy() {
    return this.proband.study ? this.proband.study.studyName : null;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openQuestionaire(item: Encounter) {
    this._probandService.setSelectedEncounter(item, this.proband);
    this.router.navigate(['/questionnaire/form-edit',
      item.id,
      item.questionnaire.id,
      this.proband.id,
      item.study.id,
      this.proband.center.id], { relativeTo: this.activatedRoute });
  }

  viewQuestionaire(item: Encounter) {
    this._probandService.setSelectedEncounter(item, this.proband);
    this.router.navigate(['/questionnaire/form-view',
      item.id,
      item.questionnaire.id,
      this.proband.id,
      item.study.id,
      this.proband.center.id], { relativeTo: this.activatedRoute });
  }

  requestAccess(item: Encounter) {
    this._questionnaireService.createQuestionnaireRequestAccess(this.proband?.id,item?.id,item.questionnaire?.id,)
      .subscribe(res => {
        if (res['statusCode']==200) {//
          const message = res['message'];
          this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);

        }
      }, (error) => {
        this.layoutUtilsService.showActionNotification("Server error", MessageType.Create, 5000, true, false);
      });
  }
}
