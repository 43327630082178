import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '../_models/location.model';

@Injectable()

export class LocationService {

  constructor(private http: HttpClient) {
  }

  getAllLocations() {
    return this.http.get<Location[]>(`${environment.host}${environment.apiUrls.location}`);
  }

  activeDeactive(id) {
    return this.http.get<Location[]>(`${environment.host}${environment.apiUrls.location}/activateDeActivate/${id}`).toPromise();
  }

  getLocationById(id: string) {
    return this.http.get<Location>(`${environment.host}${environment.apiUrls.location}/${id}`);
  }

  createLocation(location: Location) {
    return this.http.post(`${environment.host}${environment.apiUrls.location}`, location);
  }

  updateLocation(location: Location) {
    return this.http.put(`${environment.host}${environment.apiUrls.location}`, location);
  }

  deletLocation(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.location}`, options);
  }

}
