import { BaseModel } from '../../_base/crud';

export class Role extends BaseModel {
  id: string;
  title: string;
  permissions: number[];
  isCoreRole = false;
  roleName: string;
  roleDescription: string;
  clear(): void {
    this.id = '';
    this.title = '';
    this.permissions = [];
    this.isCoreRole = false;
    this.roleName = '';
    this.roleDescription = '';
  }
}
