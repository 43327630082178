import { Questionnaire } from './questionnaire.model';

export class Section {
    id: string;
    sectionName: string;
    displayOrder: number;
    isDataAvaliable: boolean;
    questionnaire: Questionnaire;

    clear(): void {
        this.id = '';
        this.sectionName = '';
        this.displayOrder = 0;
        this.isDataAvaliable = true;
        this.questionnaire = new Questionnaire();
        this.questionnaire.clear();
    }
}