import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Study } from '../../../../../../app/core/masters/_models/study.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { StudyService } from 'src/app/core/masters/_services/study.service';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { StudyEditComponent } from '../study-edit/study-edit.component';

@Component({
  selector: 'kt-study-list',
  templateUrl: './study-list.component.html',
  styleUrls: ['./study-list.component.scss']
})

export class StudyListComponent implements OnInit {

  // Table fields
  dataSource = new MatTableDataSource<Study>();;
  displayedColumns = ['select', 'studyName', 'description', 'organisation', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Study>(true, []);
  studiesResult: Study[] = [];

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _studyService: StudyService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Studies');
    this.getAllStudies();
  }

  getAllStudies() {
    this.isLoading = true;
    this._studyService.getAllStudy()
      .subscribe(res => {
        this.studiesResult = res;
        this.dataSource = new MatTableDataSource<Study>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.studiesResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.studiesResult.length) {
      this.selection.clear();
    } else {
      this.studiesResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.studyName,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(study: Study) {
    const _saveMessage = `Study successfully has been saved.`;
    const _messageType = study.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(StudyEditComponent, { data: { item: study }, width: '40%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllStudies();
    });
  }


  add() {
    const newStudy = new Study();
    newStudy.clear(); // Set all defaults fields
    this.edititem(newStudy);
  }

  deleteitem(_item: Study) {
    const _title = 'Study';
    const _description = 'Are you sure to permanently delete this study?';
    const _waitDesciption = 'Study is deleting...';
    const _deleteMessage = `Study has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._studyService.deletStudy(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllStudies();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
