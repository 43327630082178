import { BaseModel } from '../../_base/crud';
import { User } from './user.model';

export class Organisation extends BaseModel {
    id: string;
    nameOfOrganisation: string;
    email: string;
    address: string;
    pinCode: string;
    primaryContact: string;
    primaryContactMobileNUmber: string;
    isActive: boolean;
    verifyMailSent: boolean;
    expiresOn: number;

    user: User;

    clear(): void {
        this.id = '';
        this.nameOfOrganisation = '';
        this.email = '';
        this.address = '';
        this.pinCode = '';
        this.primaryContact = '';
        this.primaryContactMobileNUmber = '';
        this.isActive = false;
        this.verifyMailSent = false;
        this.expiresOn = 0;
        this.user = new User();
        this.user.clear();
    }
}
