import { Organisation } from '../../auth';

export class Questionnaire {
    id: string;
    questionnaireName: string;
    critieria: string;
    sortOrder: number;
    organisation: Organisation;
    clear(): void {
        this.id = '';
        this.questionnaireName = '';
        this.critieria = '';
        this.sortOrder = 0;
        this.organisation = new Organisation();
        this.organisation.clear();
    }
}