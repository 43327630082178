<div class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">

    <div class="card-header" *ngIf="study">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form">

        <div class="card-body" *ngIf="study">
            <form class="form" [formGroup]="studyForm">
                <mat-dialog-content>

                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                        (close)="onAlertClose($event)">
                        Oh snap! Change a few things up and try submitting again.
                    </kt-alert>

                    <div class="row">
                        <div class="col-lg-12 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter study name" formControlName="studyName" />
                                <mat-error>Study name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>study name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-lg-12 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter description" formControlName="description" />
                                <mat-error>Description is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>description</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-dialog-content>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div>
                    <button type="button" mat-raised-button [mat-dialog-close]="null" cdkFocusInitial
                        matTooltip="Cancel changes">
                        Cancel
                    </button>&nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="viewLoading" matTooltip="Save changes">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>