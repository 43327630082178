import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Organisation } from '../_models/organisation.model';

const API_USERS_URL = '/api'

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
  }
  // Authentication/Authorization
  login(username: string, password: string): Observable<User> {
    return this.http.post<User>(`${environment.host}${environment.apiUrls.login}`, { username, password });
  }

  getUserByToken(): Observable<User> {
    const userToken = localStorage.getItem(environment.authTokenKey);
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    return this.http.get<User>(`${environment.host}${environment.apiUrls.getUserByToken}`, { headers: httpHeaders });
  }

  register(user: User): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders })
      .pipe(
        map((res: User) => {
          return res;
        }),
        catchError(err => {
          return null;
        })
      );
  }

  /*
   * Submit forgot password request
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.http.get(`${environment.host}${environment.apiUrls.resetPassword}?email=` + email)
      .pipe(catchError(this.handleError('forgot-password', []))
      );
  }

  public resetPassword(token, bodyData){
    token = token || bodyData.userId;
    return this.http.post(`${environment.host}${environment.apiUrls.resetPassword}/${token}`, bodyData).toPromise();

  }
  public updatePassword(bodyData){
    return this.http.put(`${environment.host}/auth/updatePassword`, bodyData).toPromise();

  }

  public changePassword(body: any): Observable<any> {
    return this.http.put(`${environment.host}${environment.apiUrls.users}${environment.apiUrls.updatePassword}`, body)
      .pipe(catchError(this.handleError('change-password', []))
      );
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.host}${environment.apiUrls.users}`);
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${environment.host}${environment.apiUrls.users}` + `/${userId}`);
  }


  // DELETE => delete the user from the server
  deleteUser(userId: string) {
    const url = `${environment.host}${environment.apiUrls.users}/${userId}`;
    return this.http.delete(url);
  }

  // UPDATE => PUT: update the user on the server
  // tslint:disable-next-line
  updateUser(_user: User): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(`${environment.host}${environment.apiUrls.users}`, _user, { headers: httpHeaders });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: User): Observable<User> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<User>(`${environment.host}${environment.apiUrls.users}`, user, { headers: httpHeaders });
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<User[]>(`${environment.host}${environment.apiUrls.users}`).pipe(
      mergeMap(res => {
        const result = this.httpUtils.baseFilter(res, queryParams, ['status', 'type']);
        return of(result);
      })
    );
  }


  getAllPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(`${environment.host}${environment.apiUrls.permissions}`);
  }

  // getRolePermissions(roleId: number): Observable<Permission[]> {
  //   return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
  // }

  // Roles
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${environment.host}${environment.apiUrls.roles}`);
  }

  getRoleById(roleId: number): Observable<Role> {
    return this.http.get<Role>(`${environment.host}${environment.apiUrls.roles}` + `/${roleId}`);
  }

  // CREATE =>  POST: add a new role to the server
  createRole(role: Role): Observable<Role> {
    // Note: Add headers if needed (tokens/bearer)
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Role>(`${environment.host}${environment.apiUrls.roles}`, role, { headers: httpHeaders });
  }

  // UPDATE => PUT: update the role on the server
  updateRole(role: Role): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(`${environment.host}${environment.apiUrls.roles}`, role, { headers: httpHeaders });
  }

  // DELETE => delete the role from the server
  deleteRole(roleId: string): Observable<Role> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: roleId }
    }
    return this.http.delete<Role>(`${environment.host}${environment.apiUrls.roles}`, options);
  }

  // Check Role Before deletion
  isRoleAssignedToUsers(roleId: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.host}${environment.apiUrls.roles}` + '/checkIsRollAssignedToUser?roleId=' + roleId);
  }

  findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<Role[]>(`${environment.host}${environment.apiUrls.roles}`).pipe(
      mergeMap(res => {
        const result = this.httpUtils.baseFilter(res, queryParams, ['status', 'type']);
        return of(result);
      })
    );
  }

  //Organisations
  getAllOrganisations(): Observable<Organisation[]> {
    return this.http.get<Organisation[]>(`${environment.host}${environment.apiUrls.getAllOrganisations}`);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findOrganisation(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    return this.http.get<Organisation[]>(`${environment.host}${environment.apiUrls.getAllOrganisations}`).pipe(
      mergeMap(res => {
        const result = this.httpUtils.baseFilter(res, queryParams, ['status', 'type']);
        return of(result);
      })
    );
  }

  getOrganisationById(id: string): Observable<Organisation> {
    return this.http.get<Organisation>(`${environment.host}${environment.apiUrls.organisation}${environment.apiUrls.getOrganisation}` + `/${id}`);
  }

  // DELETE => delete the organisation from the server
  deleteOrganisation(id: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete<Organisation>(`${environment.host}${environment.apiUrls.organisation}`, options);
  }

  // UPDATE => PUT: update the organisation on the server
  updateOrganisation(organisation: Organisation): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(`${environment.host}${environment.apiUrls.organisation}`, organisation, { headers: httpHeaders });
  }

  // CREATE =>  POST: add a new organisation to the server
  createOrganisation(organisation: Organisation): Observable<Organisation> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Organisation>(`${environment.host}${environment.apiUrls.createOrganisation}`, { organisation: organisation }, { headers: httpHeaders });
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
    *
  * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return throwError(error);
    };
  }
}
