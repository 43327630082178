<div class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">

    <div class="card-header" *ngIf="encounter">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form">

        <div class="card-body" *ngIf="encounter">
            <form class="form" [formGroup]="encounterForm">
                <mat-dialog-content>

                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                        (close)="onAlertClose($event)">
                        Oh snap! Change a few things up and try submitting again.
                    </kt-alert>

                    <div class="row form-group">

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select location" formControlName="locationId">
                                    <mat-option *ngFor="let item of location$ | async" value="{{ item.id }}">
                                        {{item.nameOfLocation}}</mat-option>
                                </mat-select>
                                <mat-error>Location
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Location</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select study" formControlName="studyId">
                                    <mat-option *ngFor="let item of study$ | async" value="{{ item.id }}">
                                        {{item.studyName}}</mat-option>
                                </mat-select>
                                <mat-error>Study
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Study</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row form-group">

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select Patient" formControlName="probandId">
                                    <mat-option *ngFor="let item of proband" value="{{ item.id }}">
                                        {{item.firstName}}</mat-option>
                                </mat-select>
                                <mat-error>Patient
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Patient</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select Questionnaire Name" formControlName="questionnaireId">
                                    <mat-option *ngFor="let item of questionnaire$ | async" value="{{ item.id }}">
                                        {{item.questionnaireName}}</mat-option>
                                </mat-select>
                                <mat-error>Questionnaire Name
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Questionnaire Name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>


                </mat-dialog-content>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div>
                    <button type="button" mat-raised-button [mat-dialog-close]="null" cdkFocusInitial
                        matTooltip="Cancel changes">
                        Cancel
                    </button>&nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="viewLoading" matTooltip="Save changes">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>