import { BaseModel } from '../../_base/crud';
import { Organisation } from './organisation.model';
import { DataCollectionCenter } from "../../masters/_models/data-collection-center.model";
import {Location} from "../../masters/_models/location.model";

export class User extends BaseModel {
  id: string;
  username: string;
  password: string;
  email: string;
  accessToken: string;
  roles: string[];
  pic: string;
  fullName: string;
  occupation: string;
  companyName: string;
  phoneNumber: string;
  // address: Address;
  firstName: string;
  lastName: string;
  organisation: any;
  enabled: boolean;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  authorities: string[];
  dataCollectionCenter: any;
  location: any;
  centerName: string;
  centerCode: number;

  clear(): void {
    this.id = '';
    this.username = '';
    this.password = '';
    this.email = '';
    this.roles = [];
    this.fullName = '';
    this.accessToken = '';
    this.pic = './assets/media/users/default.jpg';
    this.occupation = '';
    this.companyName = '';
    this.phoneNumber = '';
    // this.organisation = new Organisation();
    // this.organisation.clear();
    this.organisation = { id: '' };
    this.dataCollectionCenter = { id: '' };
    this.location = { id: '' };
    this.firstName = '';
    this.lastName = '';
    this.enabled = false;
    this.accountNonExpired = false;
    this.accountNonLocked = false;
    this.credentialsNonExpired = false;
    this.authorities = [];
  }
}
