<div class="card card-custom "
    [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">

    <div class="card-header" *ngIf="section">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>
    </div>

    <div class="form">

        <div class="card-body" *ngIf="section">
            <form class="form" [formGroup]="sectionForm">
                <mat-dialog-content>

                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                        (close)="onAlertClose($event)">
                        Oh snap! Change a few things up and try submitting again.
                    </kt-alert>

                    <div class="row form-group">

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Section Name" formControlName="sectionName" />
                                <mat-error>Section name is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Section name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 ">
                            <mat-form-field class="mat-form-field-fluid">
                                <input matInput placeholder="Enter Display Order" formControlName="displayOrder" />
                                <mat-error>Display order is
                                    <strong>required</strong>
                                </mat-error>
                                <mat-hint align="start">Please enter
                                    <strong>Display order</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row form-group">

                        <div class="col-lg-12 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select placeholder="Select Questionnaire Name" formControlName="questionnaireId">
                                    <mat-option *ngFor="let item of questionnaire$ | async" value="{{ item.id }}">
                                        {{item.questionnaireName}}</mat-option>
                                </mat-select>
                                <mat-error>Questionnaire Name
                                    <strong> is Required </strong>
                                </mat-error>
                                <mat-hint align="start">
                                    <strong>Select Questionnaire Name</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>


                </mat-dialog-content>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <div class="mr-2"></div>
                <div>
                    <button type="button" mat-raised-button [mat-dialog-close]="null" cdkFocusInitial
                        matTooltip="Cancel changes">
                        Cancel
                    </button>&nbsp;
                    <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="viewLoading" matTooltip="Save changes">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>