<kt-portlet>
    <!-- PORTLET LOADING | Binded to TABLE Datasource -->
    <!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [title]="'Organisation list'" [class]="'card-head-lg'">
        <ng-container ktPortletTools>
            <button [routerLink]="['../organisations/add']" mat-raised-button color="primary"
                matTooltip="Create new Organisation" matTooltipClass="example-tooltip-red">New
                organisation</button>
            <!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
            <!-- mat-raised-button | Rectangular contained button w/ elevation  -->
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <!-- start::Body (attribute: ktPortletBody) -->
    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form kt-margin-b-30">
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search organisation" #searchInput placeholder="Search">
                            <mat-hint align="start">
                                <strong>Search</strong> in all fields
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->

            <!-- start::GROUP ACTIONS -->
            <!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
            <!-- Group actions are shared for all LISTS -->
            <div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
                [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="form-group form-group-inline">
                        <div class="form-label form-label-no-wrap">
                            <label class="font-bold font-danger-">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
                                {{ selection.selected.length }}
                            </label>

                        </div>
                        <div>
                            <button (click)="fetchOrganisations()" mat-raised-button
                                matTooltip="Fetch selected organisations" class="mat-button-mt-4">
                                <mat-icon>clear_all</mat-icon>
                                Fetch Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="id" matSortDirection="asc" matSortDisableClear>
                <!-- Checkbox Column -->

                <!-- Table with selection -->
                <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-checkbox">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
                    <mat-cell *matCellDef="let organisation">{{organisation.id}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="nameOfOrganisation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name of Organisation</mat-header-cell>
                    <mat-cell *matCellDef="let organisation">{{organisation.nameOfOrganisation}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Email</mat-header-cell>
                    <mat-cell *matCellDef="let organisation" class="email-cell">
                        <a href="organisation-management/organisations#"
                            class="font-weight-bold">{{organisation.email}}</a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
                    <mat-cell *matCellDef="let organisation">{{organisation.address}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="primaryContact">
                    <mat-header-cell *matHeaderCellDef>Contact</mat-header-cell>
                    <mat-cell *matCellDef="let organisation">
                        <span>{{ organisation.primaryContact }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let organisation">
                        <button (click)="editOrganisation(organisation.id)" mat-icon-button color="primary"
                            matTooltip="Edit organisation">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;
                       <!-- <button mat-icon-button color="warn" matTooltip="Delete organisation" type="button"
                            (click)="deleteOrganisation(organisation)">
                            <mat-icon>delete</mat-icon>
                        </button>-->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <div class="mat-table__message" *ngIf="dataSource.data.length===0">No records found</div>
            <!-- Message for empty data  -->
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->
</kt-portlet>
