// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'error', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'comment',
        loadChildren: () => import('./views/pages/comment/comment.module').then(m => m.CommentModule),
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
      },
      {
        path: 'builder',
        loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule),
      },
      {
        path: 'masters',
        loadChildren: () => import('./views/pages/masters/masters.module').then(m => m.MastersModule),
      },
      {
        path: 'mappings',
        loadChildren: () => import('./views/pages/mappings/mappings.module').then(m => m.MappingsModule),
      },
      {
        path: 'questionnaire',
        loadChildren: () => import('./views/pages/questionnaire-form/questionnaire-form.module').then(m => m.QuestionnaireFormModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/pages/report/report.module').then(m => m.ReportModule),
      },
      {
        path: 'questionnaire-request-access',
        loadChildren: () => import('./views/pages/questionnaire-request-access/questionnaire-request-access.module').then(m => m.QuestionnaireRequestAccessModule),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
