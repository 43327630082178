import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Encounter } from 'src/app/core/masters/_models/encounter.model';
import { Observable, Subscription, of } from 'rxjs';
import { Study } from 'src/app/core/masters/_models/study.model';
import { Location } from 'src/app/core/masters/_models/location.model';
import { Proband, ProbandRespone } from 'src/app/core/masters/_models/proband.model';
import { Questionnaire } from 'src/app/core/masters/_models/questionnaire.model';
import { EncounterService } from 'src/app/core/masters/_services/encounter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { LocationService } from 'src/app/core/masters/_services/location.service';
import { StudyService } from 'src/app/core/masters/_services/study.service';
import { ProbandService } from 'src/app/core/masters/_services/proband.service';
import { QuestionnaireService } from 'src/app/core/masters/_services/questionnaire.service';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { currentUserOrganisation, Organisation } from 'src/app/core/auth';

@Component({
  selector: 'kt-encounter-edit',
  templateUrl: './encounter-edit.component.html',
  styleUrls: ['./encounter-edit.component.scss']
})
export class EncounterEditComponent implements OnInit {

  encounterForm: FormGroup;
  encounter: Encounter;
  organisation: Organisation;
  encounter$: Observable<Encounter>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  location$: Observable<Location[]>;
  study$: Observable<Study[]>;
  probandResponse$: Observable<ProbandRespone>;
  questionnaire$: Observable<Questionnaire[]>;
  proband: Proband[];
  private subscriptions: Subscription[] = [];

  constructor(private _encounterService: EncounterService,
    private _locationService: LocationService,
    private _studyService: StudyService,
    private _probandService: ProbandService,
    private _questionnaireService: QuestionnaireService,
    public dialogRef: MatDialogRef<EncounterEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    this.encounter$ = of(this.data.item);

    const elementSubscription = this.encounter$.subscribe(res => {
      if (!res) {
        return;
      }

      this.encounter = new Encounter();
      this.encounter = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(elementSubscription);
    this.location$ = this._locationService.getAllLocations();
    this.study$ = this._studyService.getAllStudy();
    this.probandResponse$ = this._probandService.getAllProband(0, 10, "",1,50);
    this.probandResponse$.subscribe(s => this.proband = s.content);
    this.questionnaire$ = this._questionnaireService.getAllQuestionnaire();

  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.encounterForm = this.FB.group({

      locationId: [this.encounter.location?.id, Validators.required],
      studyId: [this.encounter.study?.id, Validators.required],
      probandId: [this.encounter.proband?.id, Validators.required],
      questionnaireId: [this.encounter.questionnaire?.id, Validators.required]

    });
  }

  prepareData(): Encounter {
    const controls = this.encounterForm.controls;
    const _data = new Encounter();
    _data.clear();
    _data.id = this.encounter.id;
    _data.organisation.id = this.organisation?.id;
    _data.location.id = controls.locationId.value;
    _data.study.id = controls.studyId.value;
    _data.proband.id = controls.probandId.value;
    _data.questionnaire.id = controls.questionnaireId.value;
    _data.questionnaireStatus = 'Initiated';

    return _data;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.encounterForm.controls;
    /** check form */
    if (this.encounterForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedelement = this.prepareData();
    if (editedelement.id !== '') {
      this.updateEncounter(editedelement);
    } else {
      this.createEncounter(editedelement);
    }
  }


  updateEncounter(_dataElement: Encounter) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._encounterService.updateEncounter(_dataElement)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _dataElement,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createEncounter(_dataElement: Encounter) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._encounterService.createEncounter(_dataElement)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _dataElement,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.encounter && this.encounter.id) {
      return `Edit proforma - '${this.encounter.questionnaireStatus}'`;
    }
    return 'New proforma';
  }

}
