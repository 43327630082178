import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Study } from '../_models/study.model';

@Injectable()
export class StudyService {


  constructor(private http: HttpClient) {
  }

  getAllStudy() {
    return this.http.get<Study[]>(`${environment.host}${environment.apiUrls.study}`);
  }

  getStudyById(id: string) {
    return this.http.get<Study>(`${environment.host}${environment.apiUrls.study}/${id}`);
  }

  createStudy(study: Study) {
    return this.http.post(`${environment.host}${environment.apiUrls.study}`, study);
  }

  updateStudy(study: Study) {
    return this.http.put(`${environment.host}${environment.apiUrls.study}`, study);
  }

  deletStudy(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.study}`, options);
  }
}
