// Angular
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// RXJS
import { Subscription } from 'rxjs';
// NGRX
// Services
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
import { SubheaderService } from '../../../../../core/_base/layout';
import { Organisation } from '../../../../../core/auth/_models/organisation.model';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../../../../app/core/auth';

@Component({
  selector: 'kt-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent implements OnInit {
  // Table fields
  dataSource = new MatTableDataSource<Organisation>();;
  displayedColumns = ['select', 'nameOfOrganisation', 'email', 'address', 'primaryContact', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Organisation>(true, []);
  organisationsResult: Organisation[] = [];
  isLoading: boolean = true;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   *
   * @param activatedRoute: ActivatedRoute
   * @param store: Store<AppState>
   * @param router: Router
   * @param layoutUtilsService: LayoutUtilsService
   * @param subheaderService: SubheaderService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private auth: AuthService) { }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Organisation');
    this.loadOrganisationsList();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load organisations list
   */
  loadOrganisationsList() {
    this.isLoading = true;
    this.auth.getAllOrganisations()
      .subscribe(res => {
        this.organisationsResult = res;
        this.dataSource = new MatTableDataSource<Organisation>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /** ACTIONS */
  /**
   * Delete organisation
   *
   * @param _item: User
   */
  deleteOrganisation(_item: Organisation) {
    const _title = 'Organisation Delete';
    const _description = 'Are you sure to permanently delete this organisation?';
    const _waitDesciption = 'Organisation is deleting...';
    const _deleteMessage = `Organisation has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.auth.deleteOrganisation(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.loadOrganisationsList();
        });
      }
    });
  }

  /**
   * Fetch selected rows
   */
  fetchOrganisations() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.nameOfOrganisation}, ${elem.email}`,
        id: elem.id.toString(),
        status: elem.address
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.organisationsResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.organisationsResult.length) {
      this.selection.clear();
    } else {
      this.organisationsResult.forEach(row => this.selection.select(row));
    }
  }


  /**
   * Redirect to edit page
   *
   * @param id
   */
  editOrganisation(id) {
    this.router.navigate(['../organisations/edit', id], { relativeTo: this.activatedRoute });

  }

}
