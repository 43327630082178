import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Questionnaire } from '../_models/questionnaire.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class QuestionnaireService {

  constructor(private http: HttpClient) {
  }

  getAllQuestionnaire() {
    return this.http.get<Questionnaire[]>(`${environment.host}${environment.apiUrls.questionnaire}`);
  }

  getQuestionnaireById(id: string) {
    return this.http.get<Questionnaire>(`${environment.host}${environment.apiUrls.questionnaire}/${id}`);
  }

  createQuestionnaire(element: Questionnaire) {
    return this.http.post(`${environment.host}${environment.apiUrls.questionnaire}`, element);
  }

  updateQuestionnaire(element: Questionnaire) {
    return this.http.put(`${environment.host}${environment.apiUrls.questionnaire}`, element);
  }

  deletQuestionnaire(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.questionnaire}`, options);
  }

  createQuestionnaireRequestAccess(probandId: string, encounterId: string, questionnaireId: string,) {
    return this.http.get(`${environment.host}${environment.apiUrls.questionnaireRequestAccess}/createRequestAccess/${probandId}/${encounterId}/${questionnaireId}`);
  }

  uploadQuestionnaire(file: File, organisationId: string, questionnaireName: string) {
    var formdata = new FormData();

    formdata.append("file", file);

    return this.http.post(`${environment.host}${environment.apiUrls.questionnaire}/upload/${organisationId}/${questionnaireName}`, formdata);
  }
}
