import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataElement } from '../_models/data-element.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataElementsService {

  constructor(private http: HttpClient) {
  }

  getAllDataElements() {
    return this.http.get<DataElement[]>(`${environment.host}${environment.apiUrls.dataElement}`);
  }

  getDataElementById(id: string) {
    return this.http.get<DataElement>(`${environment.host}${environment.apiUrls.dataElement}/${id}`);
  }

  createDataElement(element: DataElement) {
    return this.http.post(`${environment.host}${environment.apiUrls.dataElement}`, element);
  }

  updateDataElement(element: DataElement) {
    return this.http.put(`${environment.host}${environment.apiUrls.dataElement}`, element);
  }

  deletDataElement(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.dataElement}`, options);
  }

}
