import { SalutationType } from './salutation-type.model';
import { Organisation } from '../../auth';
import { Location } from './location.model';
import { DataCollectionCenter } from './data-collection-center.model';
import { Study } from './study.model';
import { Encounter } from './encounter.model';

export class Proband {
    id: string;
    salutationType: SalutationType;
    firstName: string;
    lastName: string;
    gender: number;
    maritalStatus: string;
    /* aadharId: string;*/
    age: number;
    dateOfBirth: any;
    address1: string;
    mobileNumber: string;
    email: string;
    organisation: Organisation;
    location: Location;
    center: DataCollectionCenter;
    guardianName: string;
    guardianPh: string;
    state: string;
    city: string;
    taluk: string;
    districtName: string;
    zip: string;
    bloodGroup: string;
    nationality: string;
    occupation: string;
    phoneNumber: string;
    identifier: string;
    fullName: string;
    todayAge: string;
    genderName: string;
    patientType: string;
    patientId: string;
    genderShortName: string;
    primaryDoctor: string;
    fatherOrHusbandName: string;
    husbandName: string;
    motherTounge: string;
    areaName: string;
    // profilePicture: [
    //     string
    // ];
    patientStatus: string;
    referredDoctor: string;
    sourceFrom: string;
    referralProvider: string;
    study: Study;
    encounter: Encounter[]

    clear(): void {
        this.id = '';
        this.salutationType = new SalutationType();
        this.salutationType.clear();
        this.firstName = '';
        this.lastName = '';
        this.gender = 0;
        this.maritalStatus = '';
        /*this.aadharId = '';*/
        this.age = 0;
        this.dateOfBirth = new Date();
        this.address1 = '';
        this.mobileNumber = '';
        this.email = '';
        this.organisation = new Organisation();
        this.organisation.clear();
        this.location = new Location();
        this.location.clear();
        this.center = new DataCollectionCenter();
        this.center.clear();
        this.guardianName = '';
        this.guardianPh = '';
        this.state = '';
        this.city = '';
        this.taluk = '';
        this.districtName = '';
        this.zip = '';
        this.bloodGroup = '';
        this.nationality = '';
        this.occupation = '';
        this.phoneNumber = '';
        this.identifier = '';
        this.fullName = '';
        this.todayAge = '';
        this.genderName = '';
        this.patientType = '';
        this.genderShortName = '';
        this.primaryDoctor = '';
        this.fatherOrHusbandName = '';
        this.husbandName = '';
        this.motherTounge = '';
        this.areaName = '';
        this.patientStatus = '';
        this.referredDoctor = '';
        this.sourceFrom = '';
        this.referralProvider = '';
        this.study = new Study();
        this.study.clear();
    }
}

export class ProbandRespone {
    content: Proband[]
    empty: false
    first: true
    last: false
    number: 0
    numberOfElements: 10
    size: 10
    sort: { sorted: true, unsorted: false, empty: false }
    totalElements: 9600
    totalPages: 960
}
