import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationService } from 'src/app/core/masters/_services/location.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { Location } from '../../../../../../app/core/masters/_models/location.model';
import { LocationsEditComponent } from '../locations-edit/locations-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '../../../../../../app/core/_base/layout';

@Component({
  selector: 'kt-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements OnInit {

  // Table fields
  dataSource = new MatTableDataSource<Location>();
  displayedColumns = ['select', 'nameOfLocation', 'locationCode', 'organisation', 'actions', 'isActive'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Selection
  selection = new SelectionModel<Location>(true, []);
  locationsResult: Location[] = [];

  isLoading: boolean = true;
  // Subscriptions
  private subscriptions: Subscription[] = [];


  constructor(private _locationService: LocationService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Locations');
    this.getAllLocations();
  }

  getAllLocations() {
    this.isLoading = true;
    this._locationService.getAllLocations()
      .subscribe(res => {
        this.locationsResult = res;
        this.dataSource = new MatTableDataSource<Location>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.locationsResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.locationsResult.length) {
      this.selection.clear();
    } else {
      this.locationsResult.forEach(row => this.selection.select(row));
    }
  }

  /**
  * Fetch selected rows
  */
  fetchitems() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: elem.nameOfLocation,
        id: elem.id.toString(),
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }


  edititem(location: Location) {
    const _saveMessage = `Location successfully has been saved.`;
    const _messageType = location.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(LocationsEditComponent, { data: { item: location }, width: '40%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
      this.getAllLocations();
    });
  }


  add() {
    const newLocation = new Location();
    newLocation.clear(); // Set all defaults fields
    this.edititem(newLocation);
  }

  deleteitem(_item: Location) {
    const _title = 'Location';
    const _description = 'Are you sure to permanently delete this location?';
    const _waitDesciption = 'Location is deleting...';
    const _deleteMessage = `Location has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._locationService.deletLocation(_item.id).subscribe(res => {
          this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          this.getAllLocations();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async deactivate(item) {
    try {
      const data = await this._locationService.activeDeactive(item.id);
      this._locationService.getAllLocations();
      window.location.reload();
      this.isLoading = false;
    } catch(err) {
      console.log(err);
      window.location.reload();
      this.isLoading = false;
    }
  }

}
