import { Organisation } from '../../auth';
import { Proband } from './proband.model';
import { Location } from './location.model';
import { Questionnaire } from './questionnaire.model';
import { Study } from './study.model';

export class Encounter {
    id: string;
    organisation: Organisation;
    proband: Proband;
    location: Location;
    questionnaire: Questionnaire;
    study: Study;
    questionnaireStatus: string;

    clear(): void {
        this.id = '';
        this.organisation = new Organisation();
        this.organisation.clear();
        this.proband = new Proband();
        this.proband.clear();
        this.location = new Location();
        this.location.clear();
        this.questionnaire = new Questionnaire();
        this.questionnaire.clear();
        this.study = new Study();
        this.study.clear();
        this.questionnaireStatus = 'Initiated';
    }
}