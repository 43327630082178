// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  // host: 'http://localhost:9194/ecdr',
  //host: 'http://94.16.114.192:9191/ecdr',
  //host: 'http://46.38.242.225:9192/ecdr',
  host: 'https://edc.iradatabaseard.in/ecdr',
  apiUrls: {
    login: '/auth/signin',
    getUserByToken: '/auth/getUserByToken',
    organisation: '/organisation',
    createOrganisation: '/organisation/create',
    getAllOrganisations: '/organisation/getAllOrganisations',
    getOrganisation: '/getOrganisation',
    roles: '/roles',
    users: '/users',
    updatePassword: '/updatePassword',
    resetPassword: '/auth/resetPassword',
    permissions: '/permissions',
    location: '/location',
    study: '/study',
    dataElement: '/dataElementsType',
    questionMaster: '/questionMasterQuestion',
    salutationType: '/salutationType',
    dataCollectionCenter: '/dataCollectionCenter',
    proband: '/proband',
    questionnaire: '/questionnaire',
    encounter: '/encounter',
    section: '/section',

    dataMapping: '/dataMapping',
    //mappings
    questionMapping: '/questionMapping',
    getQuestionnarie: '/getQuestionnarie',

    probandStudyQuestionnaireResponse: '/probandStudyQuestionnaireResponse',
    getProbandStudyQuestionnaireResponse: '/getProbandStudyQuestionnaireResponse',
    questionnaireFinish: '/create/',
    questionnaireRequestAccess: '/questionnaireRequestAccess',
    questionComments: '/questionComments'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
