<kt-portlet>

    <kt-portlet-header [title]="'Section list'" [class]="'card-head-lg'">

        <ng-container ktPortletTools>
            <button (click)="add()" mat-raised-button matTooltip="Create new section"
                matTooltipClass="example-tooltip-red" color="primary" type="button">
                <span>New Section</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form kt-margin-b-30">
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search" placeholder="Search"
                                (keyup)="applyFilter($event.target.value)">
                            <mat-hint align="start">
                                <strong>Search</strong> in all fields
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->


            <div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
                [ngClass]="{'show' : selection.selected.length > 0}">
                <!-- We show 'Group Actions' div if smth are selected -->
                <div class="col-xl-12">
                    <div class="form-group form-group-inline">
                        <div class="form-label form-label-no-wrap">
                            <label class="font-bold font-danger">
                                <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
                                {{ selection.selected.length }}
                            </label>
                        </div>
                        <div>
                            <button (click)="fetchitems()" mat-raised-button matTooltip="Fetch selected locations"
                                class="mat-button-mt-4">
                                <mat-icon>clear_all</mat-icon>
                                Fetch Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::GROUP ACTIONS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->


        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="id" matSortDirection="asc" matSortDisableClear>
                <!-- Checkbox Column -->

                <!-- Table with selection -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-checkbox">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Section Name</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.sectionName}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="displayOrder">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>DisplayOrder</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.displayOrder}}
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="questionnaire">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Questionnaire</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.questionnaire? item.questionnaire.questionnaireName:''}}
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button mat-icon-button color="primary" (click)="edititem(item)">
                            <mat-icon>create </mat-icon>
                        </button>&nbsp;
                       <!-- <button mat-icon-button color="warn" matTooltip="Delete item" type="button"
                            (click)="deleteitem(item)">
                            <mat-icon>delete</mat-icon>
                        </button>-->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <div class="mat-table__message" *ngIf="dataSource.data.length===0">No records found</div>
            <!-- Message for empty data  -->
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->
</kt-portlet>
