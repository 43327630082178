import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Section } from '../_models/section.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class SectionService {

  constructor(private http: HttpClient) {
  }

  getAllSection() {
    return this.http.get<Section[]>(`${environment.host}${environment.apiUrls.section}`);
  }

  getSectionById(id: string) {
    return this.http.get<Section>(`${environment.host}${environment.apiUrls.section}/${id}`);
  }

  createSection(element: Section) {
    return this.http.post(`${environment.host}${environment.apiUrls.section}`, element);
  }

  updateSection(element: Section) {
    return this.http.put(`${environment.host}${environment.apiUrls.section}`, element);
  }

  deletSection(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.section}`, options);
  }
}
