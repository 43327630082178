import { DataElement } from './data-element.model';

export class QuestionMaster {
    id: string;
    questionName: string;
    description: string;
    instructions: string;
    specialInstructions: string;
    questionValues: string;
    dataElementsType: DataElement;

    clear(): void {
        this.id = '';
        this.questionName = '';
        this.description = '';
        this.instructions = '';
        this.specialInstructions = '';
        this.questionValues = '';
        this.dataElementsType = new DataElement();
        this.dataElementsType.clear();
    }
}