<kt-portlet>
    <kt-portlet-header [title]="getComponentTitle()" [class]="'card-head-lg'">
        <ng-container ktPortletTools>
            <a [routerLink]="['/masters/proband']" class="btn btn-secondary mr-2" mat-raised-button
                matTooltip="Back to the users list">
                <i class="la la-arrow-left"></i>
                <span class="hidden-mobile">Back</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <!--begin::Form-->
        <div>
            <form class="form" autocomplete="off">
                <div class="form-section form-section-first pb-5">
                    <h3>Basic Info</h3>
                    <div class="form-group row">
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Patient Id</div>
                            {{proband.patientId}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">First Name</div>
                            {{proband.firstName}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Last Name</div>
                            {{proband.lastName}}
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Gender</div>
                            {{proband.gender===1?'Female':'Male'}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Marital Status</div>
                            {{proband.maritalStatus}}
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Date Of Birth</div>
                            {{proband.dateOfBirth | date:'dd-MM-yyyy'}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Email</div>
                            {{proband.email}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Mobile</div>
                            {{proband.mobileNumber}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Guardian Name</div>
                            {{proband.guardianName}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Guardian Mobile</div>
                            {{proband.guardianPh}}
                        </div>
                        <div class="col-lg-4 kt-margin-bottom-20-mobile">
                            <div class="form-text text-muted">Address</div>
                            {{proband.address1}}
                        </div>
                    </div>
                </div>

                <div class="form ">
                    <h3>Additional Info</h3>
                    <div class="form-section form-section-first">
                        <div class="row form-group">
                            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                <div class="form-text text-muted">Salutation</div>
                                {{getSalutation()}}
                            </div>
                            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                <div class="form-text text-muted">Location</div>
                                {{getLocation()}}
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                <div class="form-text text-muted">Center</div>
                                {{getCenter()}}
                            </div>

                            <div class="col-lg-6 kt-margin-bottom-20-mobile">
                                <div class="form-text text-muted">Study</div>
                                {{getStudy()}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <br><br>
        <h3>Proformas</h3>

        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="form kt-margin-b-30">
            <!-- start::FILTERS -->
            <div class="form-filtration">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search" placeholder="Search"
                                (keyup)="applyFilter($event.target.value)">
                            <mat-hint align="start">
                                <strong>Search</strong> in all fields
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- end::FILTERS -->
        </div>
        <!-- end::FILTERS & GROUP ACTIONS -->


        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
                matSortActive="sortOrder" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="questionnaire">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Questionnaire</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.questionnaire? item.questionnaire.questionnaireName:''}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="study">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Study</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.study? item.study.studyName:''}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="questionnaireStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.questionnaireStatus}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UpdatedBy">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</mat-header-cell>
                  <mat-cell *matCellDef="let item">{{item.lastModifiedBy}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UpdatedDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Updated Date</mat-header-cell>
                  <mat-cell *matCellDef="let item">{{item.lastModifiedDate}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button matTooltip="Edit Questionnaire" matTooltipClass="example-tooltip-red" mat-icon-button
                            color="primary" (click)="openQuestionaire(item)" [disabled]="item.questionnaireStatus=='Completed'">
                            <mat-icon>create </mat-icon>
                        </button>&nbsp;
                        <button matTooltip="View Questionnaire" matTooltipClass="example-tooltip-red" mat-icon-button
                            color="accent" (click)="viewQuestionaire(item)" >
                            <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      <button matTooltip="Reuqest For edit access" matTooltipClass="example-tooltip-red" mat-icon-button
                              color="accent" (click)="requestAccess(item)" [disabled]="item.questionnaireStatus!='Completed'">
                        <mat-icon>lock</mat-icon>
                      </button>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <div class="mat-table__message" *ngIf="dataSource.data.length===0">No records found</div>
            <!-- Message for empty data  -->
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
</kt-portlet>
