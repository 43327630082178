import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataCollectionCenter } from 'src/app/core/masters/_models/data-collection-center.model';
import { Observable, Subscription, of } from 'rxjs';
import { DataCollectionCenterService } from '../../../../../../app/core/masters/_services/data-collection-center.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '../../../../../../app/core/_base/crud';
import { LocationService } from 'src/app/core/masters/_services/location.service';
import { Location } from 'src/app/core/masters/_models/location.model';
import { AppState } from 'src/app/core/reducers';
import { Store, select } from '@ngrx/store';
import { currentUserOrganisation, Organisation } from 'src/app/core/auth';

@Component({
  selector: 'kt-data-collection-center-edit',
  templateUrl: './data-collection-center-edit.component.html',
  styleUrls: ['./data-collection-center-edit.component.scss']
})
export class DataCollectionCenterEditComponent implements OnInit {
  centerForm: FormGroup;
  center: DataCollectionCenter;
  organisation: Organisation;
  center$: Observable<DataCollectionCenter>;
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  location$: Observable<Location[]>;
  private subscriptions: Subscription[] = [];

  constructor(private _centerService: DataCollectionCenterService,
    public dialogRef: MatDialogRef<DataCollectionCenterEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private _locationService: LocationService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(currentUserOrganisation)).subscribe(res => this.organisation = res);
    this.center$ = of(this.data.item);

    const elementSubscription = this.center$.subscribe(res => {
      if (!res) {
        return;
      }

      this.center = new DataCollectionCenter();
      this.center = res;

      this.createForm();
    }, (error) => {
      this.layoutUtilsService.showActionNotification(error.error.status, MessageType.Delete, 10000, true, false);

    });
    this.subscriptions.push(elementSubscription);
    this.location$ = this._locationService.getAllLocations();
  }

  /**
 * On destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }


  createForm() {
    this.centerForm = this.FB.group({
      nameOfCenter: [this.center.nameOfCenter, Validators.required],
      centerCode: [this.center.centerCode, Validators.required],
      centerAddress: [this.center.centerAddress, Validators.required],
      primaryContactNumber: [this.center.primaryContactNumber],
      primaryMobileNumber: [this.center.primaryMobileNumber],
      emailAddress: [this.center.emailAddress, Validators.required],
      locationId: [this.center.location?.id, Validators.required]

    });
  }

  prepareData(): DataCollectionCenter {
    const controls = this.centerForm.controls;
    const _data = new DataCollectionCenter();
    _data.clear();
    _data.id = this.center.id;
    _data.nameOfCenter = controls.nameOfCenter.value;
    _data.centerCode = controls.centerCode.value;
    _data.centerAddress = controls.centerAddress.value;
    _data.primaryContactNumber = controls.primaryContactNumber.value;
    _data.primaryMobileNumber = controls.primaryMobileNumber.value;
    _data.emailAddress = controls.emailAddress.value;
    _data.organisation.id = this.organisation?.id;
    _data.location.id = controls.locationId.value;

    return _data;
  }

	/**
	 * Save data
	 */
  onSubmit() {
    this.loadingAfterSubmit = false;

    this.hasFormErrors = false;
    const controls = this.centerForm.controls;
    /** check form */
    if (this.centerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    const editedelement = this.prepareData();
    if (editedelement.id !== '') {
      this.updateCenter(editedelement);
    } else {
      this.createCenter(editedelement);
    }
  }


  updateCenter(_dataElement: DataCollectionCenter) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    const componentSubscriptions = this._centerService.updateDataCenter(_dataElement)
      .subscribe(res => {
        if (res) {
          this.viewLoading = false;
          this.dialogRef.close({
            _dataElement,
            isEdit: true
          });
        }
      });

    this.subscriptions.push(componentSubscriptions);

  }

  createCenter(_dataElement: DataCollectionCenter) {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const componentSubscriptions = this._centerService.createDataCenter(_dataElement)
      .subscribe(res => {

        if (!res) {
          return;
        }

        this.viewLoading = false;
        this.dialogRef.close({
          _dataElement,
          isEdit: false
        });
      });

    this.subscriptions.push(componentSubscriptions);
  }


  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.center && this.center.id) {
      return `Edit center - '${this.center.nameOfCenter}'`;
    }
    return 'New center';
  }

}
