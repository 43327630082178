import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SalutationType } from '../_models/salutation-type.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class SalutationTypeService {

  constructor(private http: HttpClient) {
  }

  getAllsalutationTypes() {
    return this.http.get<SalutationType[]>(`${environment.host}${environment.apiUrls.salutationType}`);
  }

  getSalutationTypeById(id: string) {
    return this.http.get<SalutationType>(`${environment.host}${environment.apiUrls.salutationType}/${id}`);
  }

  createSalutationType(element: SalutationType) {
    return this.http.post(`${environment.host}${environment.apiUrls.salutationType}`, element);
  }

  updateSalutationType(element: SalutationType) {
    return this.http.put(`${environment.host}${environment.apiUrls.salutationType}`, element);
  }

  deletSalutationType(id: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: id }
    }
    return this.http.delete(`${environment.host}${environment.apiUrls.salutationType}`, options);
  }
}
